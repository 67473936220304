<template>
  <div class="uploadcontainer">
    <loading :active.sync="isSaving" :can-cancel="false"></loading>
    <!--UPLOAD-->
    <div
      id="myModal"
      class="custom-modal"
      :style="showPreview ? previewShowStyle : previewHideStyle"
    >
      <!-- Modal content -->
      <div class="modal-content" :style="{ height: newHeight }">
        <span class="close" @click="closeModal">
          <img src="../../assets/close.svg" alt="close" />
        </span>
        <div class="video-preview" v-if="videoFile">
          <video :key="url" width="600" controls>
            <source :src="url" :type="typeFile" />
          </video>
          <button @click="downloadVideo" class="button">Загрузить видео</button>
        </div>
        <div class="file-preview" v-else-if="commonFile">
          <div class="common-file-preview">
            <img alt="previewImg" :src="url" @error="errorHandler($event)" />
            <p>No preview for this file</p>
          </div>
        </div>
        <div v-else>
          <div class="instagram-mode">
            <custom-checkbox v-model="instagramMode" title="Instagram" />
          </div>
          <div class="original-mode">
            <custom-checkbox v-model="originalMode" title="Original" />
          </div>
          <div class="cropper" v-if="showCropper && url && instagramMode">
            <cropper
              :src="url"
              ref="cropper"
              :resizeImage="true"
              :stencil-props="{ aspectRatio: 1 }"
            />
            <button class="button" @click="cropImage">Crop image</button>
          </div>
          <div class="new-preview" v-if="!instagramMode || !showCropper">
            <img
              alt="previewImg"
              id="previewImg"
              :src="url"
              @error="errorHandler($event)"
            />
            <div class="logo__container" v-if="!commonFile && !originalMode">
              <img
                alt="logo"
                id="logo1"
                src="./../../assets/logo.svg"
                width="100"
                @click="setLogo('white')"
              />&nbsp;
              <img
                alt="logo"
                id="logo2"
                src="./../../assets/logoblack.png"
                width="100"
                @click="setLogo('black')"
              />
            </div>
          </div>
        </div>
        <div class="btn-upload-container" v-if="commonFile || originalMode">
          <button @click="uploadFile" class="button">Загрузить файл</button>
        </div>
      </div>
    </div>
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <div :style="disabled ? 'display: none' : ''" class="dropbox">
        <!--  @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" -->
        <label for="contained-button-file" @click="loadFile" class="button">
          <img src="../../assets/download.svg" alt="" />
          <p>Upload file</p>
        </label>
        <input
          type="file"
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="onFileChange"
          accept="*/*"
          class="input-file"
          id="contained-button-file"
        />
        <div id="preview"></div>
        <!-- <p v-if="isInitial">
          Drag your file(s) here to begin<br> or click to browse
        </p> -->
      </div>
    </form>
  </div>
</template>

<script>
import { api } from "@/api";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Library } from "@/mixins/mixins";
import CustomCheckbox from "@/components/custom-checkbox/CustomCheckbox";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "LoadingButton",
  components: {
    CustomCheckbox,
    Loading,
    Cropper,
  },
  props: {
    prefixProp: {
      type: String,
      required: false,
      default: "",
    },
    disabled: Boolean,
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      url: null,
      showPreview: false,
      previewShowStyle: "display: block",
      previewHideStyle: "display: none",
      file: null,
      newHeight: null,
      typeFile: "",
      fileName: "",
      videoFile: false,
      commonFile: false,
      instagramMode: false,
      originalMode: false,
      showCropper: true,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  mounted() {
    this.reset();
  },
  mixins: [Library],
  methods: {
    cropImage() {
      const result = this.$refs.cropper.getResult();

      // #1 Make grayscale result
      const canvas = result.canvas;
      const context = canvas.getContext("2d");
      context.drawImage(canvas, 0, 0);

      this.showCropper = false;
      const url = canvas.toDataURL(this.typeFile);
      this.url = url;
      api.downloadFile(url).then(({ data: blob }) => {
        this.file = new File([blob], this.fileName, { type: this.typeFile });
      });
    },
    noCrop() {
      this.showCropper = false;
    },
    setLogo(type) {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("original", this.originalMode);
      this.save(formData, type);
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.url = null;
      this.file = null;
      this.url = null;
      this.typeFile = "";
      this.fileName = "";
      this.showCropper = true;
    },
    downloadVideo() {
      const formData = new FormData();
      formData.append("file", this.file);

      this.currentStatus = STATUS_SAVING;

      api
        .post(`/api/s3/video?prefix=${this.prefixProp}`, formData)
        .then(() => {
          this.fetchAPIData();
          this.reset();
          this.showPreview = false;
        })
        .catch((err) => {
          console.log(
            "The API is facing issues. Please try again later.n" + err
          );
          this.currentStatus = STATUS_FAILED;
        });
    },
    uploadFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("original", this.originalMode);
      this.save(formData, "white");
    },
    save(formData, type) {
      this.currentStatus = STATUS_SAVING;

      api
        .post(`/api/s3/upload?prefix=${this.prefixProp}&logo=${type}`, formData)
        .then(() => {
          this.fetchAPIData();
          this.reset();
          this.showPreview = false;
        })
        .catch((err) => {
          console.log(
            "The API is facing issues. Please try again later.n" + err
          );
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      console.log(fieldName, fileList);

      if (!fileList.length) return;

      fileList.forEach((value) => {
        //files[key] = value;
        const formData = new FormData();
        formData.append("file", value);
        this.save(formData);
      });
      /*
       const formData = new FormData();
       // append the files to FormData
       Array
       .from(Array(fileList.length).keys())
       .map(x => {
       formData.append(fieldName, fileList[x], fileList[x].name);
       });

       // save it
       this.save(formData);*/
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      const reader = new FileReader();

      this.fileName = e.target.files[0].name;

      this.commonFile = false;
      this.videoFile = e.target.files[0].type.includes("video");
      this.typeFile = e.target.files[0].type;
      /* if (this.typeFile.includes('video')) {
        reader.readAsDataURL(this.file);
        // blob url video
        console.log('URL.createObjectURL(this.file);', URL.createObjectURL(this.file));
        reader.onload = function(e) {
          // video.src = e.target.result;
          // base64 url video
          console.log('e.target.result', e.target.result);
        }
        return;
      }*/
      reader.onload = () => {
        const image = new Image();
        image.src = URL.createObjectURL(e.target.files[0]);
        image.onload = () => {
          this.newHeight = Math.round((600 / image.width) * image.height);
        };
      };
      reader.readAsDataURL(e.target.files[0]);

      this.url = URL.createObjectURL(this.file);
      this.showPreview = true;
    },
    loadFile() {
      this.$emit("load-file");
    },
    closeModal() {
      this.showPreview = false;
      this.reset();
    },
    errorHandler(e) {
      const images = require.context("../../assets/", false);
      e.target.src = images("./no_preview_2.png");
      this.videoFile = false;
      this.commonFile = true;
    },
  },
};
</script>

<style src="./operations.scss" lang="scss"></style>
