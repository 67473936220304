<template>
  <TheModal :title="title" @close="closeModal">
    <EditFilters
      :fullPathProp="folderFullPath"
      :multiSelectedFiles="multiSelectedFiles"
    />
    <MoveFolder
      v-if="selectedType === 'folder'"
      @move="moveHandler"
      :fullPathProp="folderFullPath"
    />
    <MoveFile
      v-if="selectedType === 'file'"
      @move="moveHandler"
      :fullPathProp="folderFullPath"
    />
    <button type="button" class="button" @click="closeModal">Save</button>
  </TheModal>
</template>

<script>
import EditFilters from "@/components/filters/edit/EditFilters";
import MoveFolder from "@/components/operations/folder/move/MoveFolder";
import MoveFile from "@/components/operations/folder/move/MoveFile";
import TheModal from "@/components/modal/TheModal";

export default {
  name: "EditFolder",
  components: { TheModal, MoveFolder, MoveFile, EditFilters },
  props: {
    folderName: {
      type: String,
      default: "",
    },
    folderFullPath: {
      type: [String, Array],
      default: "",
    },
    multiSelectedFiles: {
      type: Array,
      required: false,
    },
    closeModal: {
      type: Function,
    },
    selectedType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fullPath: "",
      title: "",
    };
  },
  mounted() {
    // console.log('Edit Folder mounted', this.selectedType);
    this.title = `Edit ${this.selectedType}`;
  },
  methods: {
    moveHandler(value) {
      console.log(value, "moveValue");
    },
  },
};
</script>

<style src="../../operations.scss" lang="scss"></style>
