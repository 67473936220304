<template>
  <div>
    <new-text-input
      :value="newName"
      name="new name file"
      :width="230"
      @change="changeInput('newName', $event)"
    />
  </div>
</template>

<script>
import NewTextInput from "@/components/text-input/NewTextInput";
import { api } from "@/api";
import { Library } from "@/mixins/mixins";
import { mapState } from "vuex";

export default {
  name: "MoveFolder",
  components: { NewTextInput },
  computed: {
    ...mapState(["library"]),
  },
  props: {
    fullPathProp: {
      type: String,
      default: "",
    },
    loadingValue: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [Library],
  data() {
    return {
      newName: "",
    };
  },
  methods: {
    changeInput(key, value) {
      // console.log('changeInput', key, value);
      this.$emit("move", value);
      this.newName = value;
      let oldPath = this.fullPathProp.split("/");
      // console.log('oldPath', oldPath);
      oldPath.splice(oldPath.length - 1);

      let newName = "";
      for (let i = 0; i < oldPath.length; i++) {
        newName += oldPath[i] + "/";
      }
      newName += value;

      if (confirm("Do you want to rename this?")) {
        api
          .post(
            `/api/s3/move`,
            JSON.stringify({
              src: this.fullPathProp,
              dst: newName,
            })
          )
          .then(() => {
            this.fetchAPIData();
          })
          .catch((err) => {
            console.log(
              "The API is facing issues. Please try again later.n" + err
            );
          });
      }
    },
  },
};
</script>

<style lang="scss"></style>
