<template>
  <TheModal title="New user" @close="closeModal">
    <table class="new-user__table">
      <tr>
        <td>
          <div class="new-user__input-container">
            <span class="new-user__label"> First Name </span>
            <text-input :value="fname" @change="changeInput('fname', $event)" />
          </div>
        </td>
        <td>
          <div class="new-user__input-container">
            <span class="new-user__label"> Last Name </span>
            <text-input :value="sname" @change="changeInput('sname', $event)" />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="new-user__input-container">
            <span class="new-user__label"> Work phone </span>
            <the-mask
              :value="wphone"
              @change="changeInput('wphone', $event)"
              mask="#-###-###-####"
              placeholder="_(___)__-__"
            />
          </div>
        </td>
        <td>
          <div class="new-user__input-container">
            <span class="new-user__label"> Mobile phone </span>
            <the-mask
              :value="mphone"
              @change="changeInput('mphone', $event)"
              mask="#-###-###-####"
              placeholder="_(___)__-__"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="new-user__input-container">
            <span class="new-user__label"> Agent </span>
            <text-input :value="agent" @change="changeInput('agent', $event)" />
          </div>
        </td>
        <td>
          <div class="new-user__input-container-dropdown">
            <span class="new-user__label-dropdown"> Access </span>
            <drop-down-select
              :value="newAccess"
              :options="options.permission"
              @change="changeInput('newAccess', $event)"
            >
              <template v-slot:default="props">
                <div>{{ props.option.name }}</div>
              </template>
            </drop-down-select>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="new-user__input-container">
            <span class="new-user__label"> E-mail </span>
            <text-input :value="email" @change="changeInput('email', $event)" />
          </div>
        </td>
        <td>
          <div class="new-user__input-container">
            <span class="new-user__label"> Password </span>
            <text-input
              :value="password"
              @change="changeInput('password', $event)"
            />
          </div>
        </td>
      </tr>
    </table>
    <button class="button" @click="sentData">Save</button>
    <span v-if="error" class="error">{{ error }}</span>
    <span v-if="success" class="success">{{ success }}</span>
  </TheModal>
</template>

<script>
import TextInput from "@/components/text-input/TextInput";
import DropDownSelect from "@/components/drop-down/DropDownSelect";
import { TheMask } from "vue-the-mask";

import { api } from "@/api";
import permission from "@/dictionary/permission";
import { FiltersMixin, Library } from "@/mixins/mixins";
import TheModal from "@/components/modal/TheModal";

export default {
  name: "NewUser",
  components: { TheModal, TextInput, DropDownSelect, TheMask },
  props: {
    prefix: String,
    multiSelectedPrefix: {
      type: Array,
      required: false,
    },
    selectedPrefix: {
      type: String,
      default: null,
      required: false,
    },
    closeModal: {
      type: Function,
    },
  },
  mixins: [FiltersMixin, Library],
  data() {
    return {
      fname: "",
      sname: "",
      wphone: "",
      mphone: "",
      agent: "",
      email: "",
      password: "",
      error: "",
      success: "",
      newAccess: "",
    };
  },
  created() {
    this.options = {
      permission,
    };
  },
  mounted() {
    this.generatePassword();
  },
  methods: {
    generatePassword() {
      let length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.password = retVal;
    },
    changeInput(key, value) {
      this[key] = value;
    },
    sentData() {
      if (!this.fname.trim() && !this.sname.trim()) {
        this.error = `Please, enter the name`;
        return;
      }
      this.error = "";
      this.success = "";
      api
        .post(
          `/api/users`,
          JSON.stringify({
            agent: this.agent,
            fname: this.fname,
            sname: this.sname,
            email: this.email,
            wphone: this.wphone,
            mphone: this.mphone,
            maxdiscount: 0,
            password: this.password,
          })
        )
        .then((res) => {
          this.setAccess(res.id, this.newAccess);
          this.success = "User is registered";
        })
        .catch((err) => {
          this.error = `Ошибка ${err}`;
          console.log(
            "The API is facing issues. Please try again later.n" + err
          );
        });
    },
  },
};
</script>

<style src="./style.scss" lang="scss"></style>
