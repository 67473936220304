<template>
  <tr>
    <td>
      <div class="permission__text-container">
        {{ fullname(item) }}
      </div>
    </td>
    <td v-if="!editPermission[item.id]" @click="editPermissionHandler(item.id)">
      <div class="permission__input-container">
        <span class="permission__label"> Access </span>
        {{ access[item.id] }}
      </div>
    </td>
    <td v-if="editPermission[item.id]">
      <div class="permission__input-container-dropdown">
        <span class="permission__label"> Editing </span>
        <drop-down-select
          refCustom="user"
          :value="permission[item.id]"
          :options="options.permission"
          @change="changePermission($event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
      </div>
    </td>
  </tr>
</template>

<script>
import DropDownSelect from "@/components/drop-down/DropDownSelect";
import { FiltersMixin } from "@/mixins/mixins";

export default {
  name: "Permissions",
  components: { DropDownSelect },
  props: {
    item: Object,
    options: Object,
    multiSelectedPrefix: Array,
  },
  data() {
    return {
      access: {},
      permission: {},
      editPermission: {},
      permissionId: null,
    };
  },
  mounted() {
    this.getAccess(this.item.access?.access, this.item.id);
  },
  methods: {
    editPermissionHandler(id) {
      this.editPermission = {
        ...this.editPermission,
        [id]: true,
      };
      this.permissionId = id;
    },
    changePermission(value) {
      this.permission = {
        ...this.permission,
        [this.permissionId]: value,
      };
      if (value) {
        this.setAccess();
        this.getAccess(value, this.item.id);
      }
    },
    fullname(user) {
      return `${user.fname} ${user.sname}`;
    },
    getAccessType(access) {
      if (access) {
        switch (access) {
          case "read": {
            return "Read";
          }
          case "write": {
            return "Write";
          }
          default: {
            return "Access denied";
          }
        }
      } else {
        {
          return "Access denied";
        }
      }
    },
    getAccess(access, id) {
      this.access = {
        ...this.access,
        [id]: this.getAccessType(access),
      };

      this.permission[id] = access || "denied";
    },
  },
  mixins: [FiltersMixin],
};
</script>

<style src="./style.scss" lang="scss"></style>
