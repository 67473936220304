<template>
  <TheModal title="Access to folder" @close="closeLink">
    <div class="permission">
      <div class="permission__body">
        <div class="button-container">
          <button class="button" @click="newUser">+ Add a user</button>
        </div>
        <div class="permission__user">
          <table>
            <template v-for="(item, index) in users">
              <Permission
                :key="index"
                :item="item"
                :options="options"
                :multiSelectedPrefix="multiSelectedPrefix"
            /></template>
          </table>
        </div>
      </div>
    </div>
  </TheModal>
</template>

<script>
import { api } from "@/api";
import permission from "@/dictionary/permission";
import { FiltersMixin } from "@/mixins/mixins";
import TheModal from "@/components/modal/TheModal";
import Permission from "./Permission.vue";
export default {
  name: "Permissions",
  components: { TheModal, Permission },
  props: {
    prefix: String,
    selectedPrefix: {
      type: String,
      default: null,
      required: false,
    },
    multiSelectedPrefix: Array,
  },
  data() {
    return {
      users: [],
    };
  },
  methods: {
    newUser() {
      this.$emit("new-user");
    },
    closeLink() {
      this.$emit("close");
    },
  },
  mixins: [FiltersMixin],
  created() {
    this.options = {
      permission,
    };
    api.setBearer(localStorage.getItem("token"));
  },
  mounted() {
    api
      .get(`/api/s3/accesses?key=${this.selectedPrefix || this.prefix}`)
      .then((response) => {
        this.users = response;
      })
      .catch((err) => {
        console.log("The API is facing issues. Please try again later.n" + err);
      });
  },
};
</script>

<style src="./style.scss" lang="scss"></style>
