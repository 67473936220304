import set from 'lodash-es/set';
import cloneDeep from 'lodash-es/cloneDeep';

export default {
    namespaced: true,
    state: {
        prefix: '',
        system: [],
        room: [],
        collection: [],
        material: [],
        modal: false
    },
    mutations: {
        setAll(state, data) {
            state.system = data.system;
            state.room = data.room;
            state.collection = data.collection;
            state.material = data.material;
        },
        setCurrentProperty(state, { path, value }) {
            set(state, path, value);
            state = cloneDeep(state);
        },
        setPrefix(state, prefix) {
            state.prefix = prefix;
        },
        clearFilters(state) {
            state.system = [];
            state.room = [];
            state.collection = [];
            state.material = [];
        },
        showModal(state, bool) {
            state.modal = bool;
        },
    },
    actions: {
        setFilters({ commit }, {data}) {
            commit('setAll', data);
        },
        setPrefix({ commit }, prefix) {
            commit('setPrefix', prefix);
        },
        setCurrentProperty({ commit }, { path, value }) {
            commit('setCurrentProperty', { path, value });
        },
    },
}