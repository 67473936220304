<template>
  <footer>
    <div class="container">
      <div class="foot_top_bl">
        <div class="navigation-menu">
          <nav class="solutions-navigation">
            <p class="list-title">Systems</p>

            <a href="https://woodandwashi.com/rollo/">Rollo</a>

            <a href="https://woodandwashi.com/flow/">Flow</a>

            <a href="https://woodandwashi.com/panel/">Panel</a>

            <a href="https://woodandwashi.com/banner/">Banner</a>

            <a href="https://woodandwashi.com/slides/">Slides</a>

          </nav>
          <nav class="materials-navigation">
            <p class="list-title">Materials</p>

            <a href="https://woodandwashi.com/washi-originals/">Washi Originals</a>

            <a href="https://woodandwashi.com/washi-specials/">Washi Specials</a>

            <a href="https://woodandwashi.com/textures-originals/">Textures Originals</a>

            <a href="https://woodandwashi.com/textures-specials/">Textures Specials</a>

            <a href="https://woodandwashi.com/ribs/">Ribs</a>

          </nav>
          <nav class="info-navigation">


            <a href="https://woodandwashi.com/about/">About Wood &amp; Washi</a>

            <a href="https://woodandwashi.com/washi-unique/">Washi Unique</a>

            <a href="https://woodandwashi.com/projects/">Projects</a>

            <a href="https://woodandwashi.com/not-standart-windows/">Custom windows</a>

            <a href="https://woodandwashi.com/cooperation/">Terms of cooperation</a>

            <a href="https://woodandwashi.com/warranty/">Delivery and warranty</a>

            <a href="https://woodandwashi.com/dealers/">Dealers</a>

            <a href="https://woodandwashi.com/contacts/">Contacts</a>

          </nav>
        </div>
        <div class="foot_confproj_bl">
          <!-- <div class="foot_config_bl">
              <div class="foot_config_el"><span class="txthid">Приложение </span>конфигуратор</div>
              <div class="foot_config_fl">
                  <div class="foot_config_el svg">
                      <a href="">
                          <svg width="34" height="37">
                              <use xlink:href="#footApp"></use>
                          </svg>
                          <span>App Store</span>
                      </a>
                  </div>
                  <div class="foot_config_el svg">
                      <a href="">
                          <svg width="34" height="37">
                              <use xlink:href="#footGoog"></use>
                          </svg>
                          <span>Google Play</span>
                      </a>
                  </div>
              </div>
          </div> -->
          <div class="foot_proj_bl">
            <div class="foot_proj_tit">
              Follow our projects
            </div>
            <div class="foot_proj_fl">
              <!-- <div class="foot_proj_el">
<a href="https://www.houzz.com/hznb/professionals/window-treatments/wood-and-washi-pfvwde-pf~1797548262?" target="_blank" title="Wood&washi">
<img src="https://woodandwashi.com/local/templates/main/img/foot_proj_el2.png" alt="houzz.ru">
</a>
</div> -->
              <div class="foot_proj_el">
                <a href="https://pin.it/4KV2cDt" target="_blank" title="Wood&amp;washi Pinterest">
                  <svg>
                    <use xlink:href="#pinterest">
                      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" id="pinterest">
                        <g clip-path="url(#clip0)">
                          <path
                              d="M15.2717 5C9.78917 5.00083 6.875 8.51333 6.875 12.3433C6.875 14.1192 7.8675 16.335 9.45667 17.0375C9.91 17.2417 9.85 16.9925 10.24 15.5008C10.2708 15.3767 10.255 15.2692 10.155 15.1533C7.88333 12.5258 9.71167 7.12417 14.9475 7.12417C22.525 7.12417 21.1092 17.6092 16.2658 17.6092C15.0175 17.6092 14.0875 16.6292 14.3817 15.4167C14.7383 13.9725 15.4367 12.42 15.4367 11.3792C15.4367 8.75583 11.5283 9.145 11.5283 12.6208C11.5283 13.695 11.9083 14.42 11.9083 14.42C11.9083 14.42 10.6508 19.5 10.4175 20.4492C10.0225 22.0558 10.4708 24.6567 10.51 24.8808C10.5342 25.0042 10.6725 25.0433 10.75 24.9417C10.8742 24.7792 12.3942 22.6108 12.82 21.0433C12.975 20.4725 13.6108 18.1558 13.6108 18.1558C14.03 18.9125 15.2383 19.5458 16.5258 19.5458C20.3558 19.5458 23.1242 16.1792 23.1242 12.0017C23.1108 7.99667 19.6833 5 15.2717 5V5Z"></path>
                        </g>
                      </svg>
                    </use>
                  </svg>
                </a>
              </div>
              <div class="foot_proj_el">
                <a href="https://www.houzz.com/hznb/professionals/window-treatments/wood-and-washi-pfvwde-pf~1797548262?"
                   target="_blank" title="Wood&amp;washi">
                  <svg>
                    <use xlink:href="#houzz">
                      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" id="houzz">
                        <path d="M15.9977 16H19V10.8878L12.9904 9.15488V7H11V16H14.0023V13.1664H15.9977V16Z"></path>
                        <path d="M11.4205 20.5938C11.1885 20.3597 10.9033 20.1865 10.5897 20.0893C10.2761 19.9921 9.94374 19.9739 9.62158 20.0364C9.29943 20.0988 8.99733 20.2399 8.7417 20.4474C8.48607 20.6549 8.2847 20.9224 8.15518 21.2266C8.05105 21.4724 7.99824 21.7373 8.00004 22.0046C7.9985 22.2674 8.04895 22.5279 8.14846 22.7708C8.24797 23.0137 8.39452 23.2341 8.57953 23.4191C8.76252 23.5998 8.97779 23.7439 9.21393 23.8436C9.71725 24.0521 10.2817 24.0521 10.785 23.8436C11.0211 23.7439 11.2364 23.5998 11.4194 23.4191C11.6046 23.2342 11.7513 23.0138 11.851 22.7709C11.9507 22.528 12.0013 22.2675 12 22.0046C12.0018 21.7373 11.949 21.4724 11.8448 21.2266C11.7441 20.9906 11.6002 20.7759 11.4205 20.5938V20.5938ZM11.0388 22.4921C10.9911 22.6314 10.9158 22.7596 10.8175 22.8687C10.7191 22.974 10.6 23.0573 10.4678 23.1135C10.1673 23.2337 9.8327 23.2337 9.5322 23.1135C9.39999 23.0574 9.28087 22.974 9.18248 22.8687C9.08389 22.7597 9.00842 22.6316 8.96071 22.4921C8.85408 22.1756 8.85408 21.8325 8.96071 21.5161C9.00845 21.3766 9.08392 21.2484 9.18248 21.1394C9.28089 21.0341 9.40001 20.9508 9.5322 20.8946C9.83263 20.7741 10.1674 20.7741 10.4678 20.8946C10.6 20.9508 10.7191 21.0342 10.8175 21.1394C10.9157 21.2486 10.991 21.3767 11.0388 21.5161C11.1453 21.8325 11.1453 22.1756 11.0388 22.4921Z"></path>
                        <path d="M21.864 20.7555V20H18.0873V20.7889H20.5007L18 23.2365V24H22V23.2111H19.3451L21.864 20.7555Z"></path>
                        <path d="M24.3451 23.2111L26.864 20.7555V20H23.0873V20.7889H25.5007L23 23.2365V24H27V23.2111H24.3451Z"></path>
                        <path d="M5.93715 19.6067C5.28749 19.3359 4.49035 19.4475 3.97449 19.8663V18H3V24H3.97511V21.641C3.97511 21.1921 4.1033 20.4392 4.96017 20.4392C5.74673 20.4392 6.02614 21.0868 6.02614 21.641V23.9994H7V21.3181C6.99938 20.512 6.61232 19.8882 5.93715 19.6067Z"></path>
                        <path d="M16.0512 22.1359C16.0503 22.2579 16.0316 22.3794 15.9957 22.497C15.9594 22.6165 15.8986 22.7287 15.8165 22.8278V22.8278C15.731 22.9298 15.6212 23.0129 15.4951 23.071C15.3388 23.1325 15.1702 23.1643 14.9997 23.1643C14.8292 23.1643 14.6606 23.1325 14.5043 23.071C14.3782 23.0129 14.2684 22.9298 14.1829 22.8278C14.1006 22.7288 14.0398 22.6165 14.0037 22.497C13.9677 22.3794 13.9488 22.258 13.9476 22.1359V20H13V22.2219C13 23.0489 13.4037 23.6003 14.2165 23.8759C14.7262 24.0425 15.2845 24.0413 15.7933 23.8726C16.7909 23.5319 17 22.7854 17 22.2186V20.0094H16.0512V22.1359Z"></path>
                      </svg>
                    </use>
                  </svg>
                </a>
              </div>
              <div class="foot_proj_el">
                <a href="https://www.architonic.com/en/microsite/wood-washi/3101389" target="_blank"
                   title="architonic.com">
                  <svg>
                    <use xlink:href="#architonic">
                      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" id="architonic">
                        <path d="M14.9307 19.8617C18.2844 19.8617 21.6381 19.8617 24.9917 19.8617C25.1057 19.8617 25.2196 19.8617 25.3336 19.8729C25.588 19.9028 25.7893 20.0968 25.8235 20.3468C25.8349 20.4214 25.8349 20.4961 25.8349 20.5707C25.8349 22.1379 25.8349 23.7051 25.8349 25.2724C25.8349 25.791 25.6222 26 25.1019 26C18.311 26 11.5201 26 4.72922 26C4.21649 26 4 25.791 4 25.2873C4 23.7201 4 22.1529 4 20.5856C4 20.0632 4.2013 19.8617 4.73302 19.8617C8.13606 19.858 11.5315 19.8617 14.9307 19.8617Z"></path>
                        <path d="M15.3303 13.9789C15.3303 14.045 15.3303 14.1111 15.3303 14.1772C15.3303 15.2138 15.3303 16.2504 15.3303 17.2904C15.3303 17.3495 15.3338 17.4052 15.3268 17.4643C15.3058 17.767 15.1624 17.9026 14.8579 17.9061C14.5325 17.9096 14.3785 17.7913 14.3715 17.4748C14.3575 16.8313 14.3645 16.1877 14.3645 15.5442C14.3645 14.4903 14.3645 13.4363 14.3645 12.3823C14.3645 12.3719 14.3645 12.358 14.3645 12.3475C14.347 11.7945 14.5675 11.6345 15.1799 11.7562C15.2953 11.7806 15.4143 11.9267 15.4738 12.0449C16.0057 13.1371 16.5271 14.2398 17.052 15.339C17.0974 15.4364 17.1464 15.5338 17.2304 15.6242C17.2339 15.5651 17.2374 15.5025 17.2374 15.4434C17.2374 14.3824 17.2444 13.3215 17.2339 12.2606C17.2269 11.7388 17.5488 11.6658 17.9373 11.7423C18.1087 11.7771 18.1997 11.9093 18.2207 12.0797C18.2312 12.1528 18.2277 12.2293 18.2277 12.3058C18.2277 13.9581 18.2277 15.6068 18.2277 17.2591C18.2277 17.2939 18.2277 17.3287 18.2277 17.3635C18.2277 17.5652 18.2102 17.7635 17.9898 17.8643C17.7798 17.9583 17.4439 17.9061 17.2899 17.7356C17.1919 17.6243 17.1114 17.4922 17.045 17.36C16.5131 16.2782 15.9847 15.1929 15.4563 14.1111C15.4318 14.0624 15.4038 14.0172 15.3758 13.9685C15.3618 13.972 15.3478 13.9755 15.3303 13.9789Z"></path>
                        <path d="M11.7166 7.52981C11.9125 7.94027 12.1015 8.33681 12.2904 8.73336C12.3989 8.96293 12.5109 9.19251 12.6194 9.42556C12.6334 9.45687 12.6509 9.48818 12.6614 9.51948C12.7698 9.79776 12.7173 10.0134 12.5179 10.1108C12.2554 10.236 11.9335 10.1526 11.804 9.90211C11.6186 9.54731 11.4541 9.18207 11.2791 8.82379C11.1112 8.47247 10.9397 8.12463 10.7787 7.76983C10.7263 7.65852 10.6563 7.62025 10.5373 7.62025C10.0754 7.62025 10.0754 7.61678 10.0754 8.06897C10.0754 8.60117 10.0789 9.13685 10.0719 9.66905C10.0684 10.0099 9.90743 10.1595 9.5715 10.1491C9.28806 10.1421 9.14109 10.0065 9.13059 9.72123C9.1201 9.47774 9.12709 9.23425 9.12359 8.99076C9.12359 7.54025 9.12359 6.09322 9.12359 4.64271C9.12359 4.19051 9.29506 4.02007 9.74297 4.02007C10.1979 4.02007 10.6528 4.00616 11.1077 4.03051C11.9825 4.07225 12.6264 4.62532 12.7838 5.48449C12.9378 6.32628 12.5774 7.11588 11.818 7.47764C11.797 7.48807 11.7725 7.50199 11.7166 7.52981ZM10.0754 5.81842C10.0754 6.06191 10.0789 6.3054 10.0754 6.5489C10.0719 6.64977 10.1069 6.69499 10.2154 6.69499C10.5128 6.69151 10.8102 6.70543 11.1042 6.68803C11.5661 6.66368 11.8635 6.3228 11.867 5.83929C11.8705 5.34536 11.5766 4.99403 11.1112 4.96621C10.8102 4.94881 10.5058 4.96273 10.2049 4.95577C10.1069 4.95577 10.0719 4.99403 10.0719 5.09143C10.0789 5.33144 10.0754 5.57493 10.0754 5.81842Z"></path>
                        <path d="M19.7075 7.07061C19.7075 6.2184 19.704 5.36618 19.7075 4.51396C19.7075 4.11742 19.9279 3.9435 20.3199 4.0235C20.5438 4.06872 20.6488 4.19742 20.6523 4.46179C20.6558 5.07747 20.6523 5.68967 20.6558 6.30536C20.6558 6.53841 20.6558 6.53841 20.8972 6.53841C21.3417 6.53841 21.7826 6.53493 22.227 6.54189C22.3669 6.54537 22.4089 6.50015 22.4054 6.36101C22.3984 5.75229 22.4019 5.14356 22.4054 4.53483C22.4089 4.10351 22.6504 3.92263 23.0668 4.03046C23.2278 4.0722 23.3187 4.17308 23.3397 4.33308C23.3537 4.42004 23.3572 4.50701 23.3572 4.59397C23.3572 6.2497 23.3572 7.90892 23.3572 9.56465C23.3572 9.59944 23.3572 9.63422 23.3537 9.66901C23.3327 10.0725 23.0073 10.2708 22.6329 10.1003C22.4369 10.0099 22.4054 9.83249 22.4054 9.64813C22.4019 9.01158 22.4054 8.37503 22.4019 7.73499C22.4019 7.45672 22.4334 7.47759 22.136 7.47759C21.7056 7.47759 21.2717 7.48107 20.8413 7.47411C20.6978 7.47063 20.6523 7.51237 20.6523 7.65847C20.6593 8.32981 20.6558 9.00115 20.6523 9.67596C20.6488 10.0064 20.4948 10.1525 20.1624 10.1456C19.8545 10.1386 19.711 9.99598 19.7075 9.67596C19.7075 8.80983 19.7075 7.94022 19.7075 7.07061Z"></path>
                        <path d="M12.6377 14.8066C12.6377 15.2484 12.6237 15.6866 12.6412 16.1284C12.6762 17.1893 11.7769 18.0902 10.4927 17.8711C9.62836 17.7215 9.07898 17.085 9.06498 16.2154C9.04748 15.2553 9.04748 14.2918 9.06848 13.3283C9.08948 12.4726 9.78233 11.7908 10.6396 11.7282C11.399 11.6725 12.0149 11.916 12.4208 12.5873C12.5677 12.8308 12.6377 13.0987 12.6377 13.3839C12.6377 13.857 12.6377 14.3335 12.6377 14.8066ZM11.7034 14.7996C11.7034 14.3474 11.7069 13.8952 11.7034 13.4465C11.6999 12.9595 11.371 12.6465 10.8671 12.6395C10.3667 12.6326 10.0063 12.9317 9.99928 13.4117C9.98529 14.344 9.98529 15.2762 9.99928 16.2084C10.0063 16.7023 10.3562 16.998 10.8776 16.9876C11.3745 16.9806 11.6999 16.6641 11.7034 16.1736C11.7069 15.7145 11.7034 15.2553 11.7034 14.7996Z"></path>
                        <path d="M5.86543 8.80283C5.61349 8.80283 5.36504 8.80631 5.11309 8.79936C4.99412 8.79588 4.94863 8.83414 4.95213 8.95936C4.95912 9.20981 4.95912 9.45678 4.95213 9.70723C4.94163 10.0238 4.79116 10.1594 4.47622 10.149C4.21378 10.1386 4.08081 10.0168 4.06331 9.75245C4.01782 9.01502 4.07031 8.29498 4.32226 7.5819C4.68618 6.56272 4.98362 5.51919 5.31255 4.48957C5.42803 4.12433 5.57499 4.00954 5.90393 4.01998C6.18037 4.02694 6.30984 4.11042 6.40082 4.37478C6.59678 4.96264 6.77874 5.55397 6.9677 6.14531C7.18816 6.83056 7.41911 7.50885 7.61857 8.19759C7.69205 8.44803 7.69905 8.72283 7.71305 8.98719C7.72704 9.24807 7.72005 9.50896 7.70255 9.76984C7.68505 10.0412 7.53458 10.1559 7.24414 10.1525C6.9747 10.149 6.82423 10.0203 6.81024 9.75593C6.79624 9.502 6.79624 9.2446 6.80674 8.99067C6.81374 8.84457 6.76475 8.79588 6.61778 8.80283C6.36583 8.80979 6.11738 8.80283 5.86543 8.80283ZM5.15508 7.86365C5.65548 7.86365 6.11738 7.86365 6.60728 7.86365C6.36583 7.10883 6.13488 6.37488 5.90043 5.63745C5.88643 5.63745 5.86893 5.63745 5.85494 5.64093C5.62398 6.37488 5.39653 7.10535 5.15508 7.86365Z"></path>
                        <path d="M14.4333 7.06726C14.4333 6.63246 14.4298 6.19765 14.4333 5.76285C14.4403 4.63931 15.3606 3.85667 16.4804 4.02363C17.1837 4.12798 17.7016 4.46539 17.9186 5.16456C17.9676 5.32457 17.9711 5.50545 17.9641 5.67937C17.9571 5.86025 17.8416 5.97503 17.6596 6.0133C17.4217 6.06547 17.2572 6.0133 17.1557 5.8359C17.0962 5.73154 17.0543 5.61328 17.0193 5.49849C16.8968 5.08455 16.5119 4.86541 16.0255 4.93498C15.6371 4.99064 15.3676 5.3037 15.3676 5.71067C15.3641 6.62202 15.3641 7.5299 15.3676 8.44125C15.3676 8.84474 15.6266 9.15433 16.0045 9.21694C16.4944 9.29694 16.8513 9.11954 16.9983 8.71604C17.0263 8.63952 17.0403 8.55951 17.0648 8.48299C17.1767 8.15254 17.3167 8.07949 17.6841 8.15601C17.8626 8.19428 17.9396 8.34037 17.9536 8.51777C18.0026 9.16824 17.5826 9.77697 16.9108 10.0274C16.0745 10.337 15.1647 10.0692 14.7062 9.37695C14.5173 9.09171 14.4333 8.77518 14.4298 8.43777C14.4298 7.98209 14.4333 7.52294 14.4333 7.06726Z"></path>
                        <path d="M22.3642 14.7996C22.3642 14.3579 22.3607 13.9196 22.3642 13.4778C22.3677 12.7161 22.7737 12.1108 23.4525 11.8499C24.1489 11.5786 25.0237 11.763 25.5066 12.2778C25.755 12.5421 25.881 12.8621 25.909 13.2169C25.93 13.5161 25.8145 13.6865 25.5626 13.7387C25.2931 13.7944 25.1182 13.6935 25.0062 13.4152C24.9887 13.3735 24.9782 13.3283 24.9677 13.283C24.8032 12.7091 24.1944 12.4621 23.673 12.7613C23.4385 12.8969 23.302 13.1056 23.302 13.3735C23.295 14.3231 23.295 15.2727 23.302 16.2258C23.3055 16.6084 23.589 16.8937 23.9949 16.9528C24.4463 17.0224 24.7997 16.8241 24.9362 16.4206C24.9747 16.3128 24.9957 16.198 25.0447 16.0901C25.1322 15.8884 25.2826 15.8223 25.5381 15.864C25.7585 15.8988 25.867 16.0136 25.8845 16.2327C25.916 16.6015 25.804 16.9319 25.5801 17.2241C24.8942 18.1285 23.1341 18.1528 22.5497 16.925C22.4377 16.6884 22.3887 16.4032 22.3712 16.1388C22.3432 15.6936 22.3642 15.2449 22.3642 14.7996Z"></path>
                        <path d="M5.39998 15.1475C5.39998 14.3822 5.39648 13.6169 5.40347 12.8517C5.40347 12.7021 5.36498 12.6465 5.20752 12.6534C4.96257 12.6639 4.71762 12.6604 4.47267 12.6534C4.14024 12.643 4.00727 12.5143 4.00027 12.2047C3.99327 11.8777 4.12274 11.7177 4.44817 11.7142C5.39648 11.7038 6.34828 11.7073 7.29658 11.7177C7.59052 11.7212 7.73049 11.8916 7.73049 12.1943C7.73049 12.4899 7.59752 12.636 7.30008 12.6534C7.05163 12.6673 6.79968 12.6673 6.54773 12.6569C6.39027 12.6499 6.33778 12.6952 6.34128 12.8586C6.34828 14.3126 6.34478 15.7666 6.34478 17.2206C6.34478 17.2832 6.34478 17.3493 6.34478 17.4119C6.34128 17.8259 6.00885 18.0346 5.62393 17.8606C5.44896 17.7841 5.39998 17.6345 5.39998 17.4606C5.39998 16.7301 5.39648 15.9997 5.39648 15.2692C5.39998 15.2275 5.39998 15.1892 5.39998 15.1475Z"></path>
                        <path d="M20.656 14.7997C20.656 15.6623 20.656 16.525 20.656 17.3911C20.656 17.8015 20.4426 17.9685 20.0367 17.892C19.8512 17.8572 19.7392 17.7424 19.7147 17.5511C19.7042 17.4711 19.7042 17.3876 19.7042 17.3076C19.7042 15.6345 19.7042 13.9579 19.7042 12.2847C19.7042 12.25 19.7042 12.2152 19.7042 12.1804C19.7147 11.8534 19.8547 11.7143 20.1766 11.7143C20.5021 11.7143 20.6525 11.8499 20.6525 12.1769C20.656 13.05 20.656 13.9266 20.656 14.7997Z"></path>
                        <path d="M25.9964 7.08106C25.9964 7.8811 25.9964 8.68114 25.9964 9.4777C25.9964 9.54031 25.9964 9.6064 25.9964 9.66901C25.9789 10.0725 25.65 10.2777 25.279 10.0969C25.195 10.0551 25.1181 9.95772 25.0866 9.87076C25.0481 9.76641 25.0481 9.64467 25.0481 9.52988C25.0446 7.89501 25.0481 6.26363 25.0481 4.62876C25.0481 4.59398 25.0481 4.55919 25.0481 4.52441C25.0586 4.09656 25.3315 3.89829 25.7374 4.05134C25.8389 4.0896 25.9229 4.21483 25.9719 4.32266C26.0139 4.40266 25.9964 4.51397 25.9964 4.61485C25.9964 5.43576 25.9964 6.25667 25.9964 7.08106Z"></path>
                        <path d="M14.9307 19.8617C18.2844 19.8617 21.6381 19.8617 24.9917 19.8617C25.1057 19.8617 25.2196 19.8617 25.3336 19.8729C25.588 19.9028 25.7893 20.0968 25.8235 20.3468C25.8349 20.4214 25.8349 20.4961 25.8349 20.5707C25.8349 22.1379 25.8349 23.7051 25.8349 25.2724C25.8349 25.791 25.6222 26 25.1019 26C18.311 26 11.5201 26 4.72922 26C4.21649 26 4 25.791 4 25.2873C4 23.7201 4 22.1529 4 20.5856C4 20.0632 4.2013 19.8617 4.73302 19.8617C8.13606 19.858 11.5315 19.8617 14.9307 19.8617Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M15.3303 13.9789C15.3303 14.045 15.3303 14.1111 15.3303 14.1772C15.3303 15.2138 15.3303 16.2504 15.3303 17.2904C15.3303 17.3495 15.3338 17.4052 15.3268 17.4643C15.3058 17.767 15.1624 17.9026 14.8579 17.9061C14.5325 17.9096 14.3785 17.7913 14.3715 17.4748C14.3575 16.8313 14.3645 16.1877 14.3645 15.5442C14.3645 14.4903 14.3645 13.4363 14.3645 12.3823C14.3645 12.3719 14.3645 12.358 14.3645 12.3475C14.347 11.7945 14.5675 11.6345 15.1799 11.7562C15.2953 11.7806 15.4143 11.9267 15.4738 12.0449C16.0057 13.1371 16.5271 14.2398 17.052 15.339C17.0974 15.4364 17.1464 15.5338 17.2304 15.6242C17.2339 15.5651 17.2374 15.5025 17.2374 15.4434C17.2374 14.3824 17.2444 13.3215 17.2339 12.2606C17.2269 11.7388 17.5488 11.6658 17.9373 11.7423C18.1087 11.7771 18.1997 11.9093 18.2207 12.0797C18.2312 12.1528 18.2277 12.2293 18.2277 12.3058C18.2277 13.9581 18.2277 15.6068 18.2277 17.2591C18.2277 17.2939 18.2277 17.3287 18.2277 17.3635C18.2277 17.5652 18.2102 17.7635 17.9898 17.8643C17.7798 17.9583 17.4439 17.9061 17.2899 17.7356C17.1919 17.6243 17.1114 17.4922 17.045 17.36C16.5131 16.2782 15.9847 15.1929 15.4563 14.1111C15.4318 14.0624 15.4038 14.0172 15.3758 13.9685C15.3618 13.972 15.3478 13.9755 15.3303 13.9789Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M11.7166 7.52981C11.9125 7.94027 12.1015 8.33681 12.2904 8.73336C12.3989 8.96293 12.5109 9.19251 12.6194 9.42556C12.6334 9.45687 12.6509 9.48818 12.6614 9.51948C12.7698 9.79776 12.7173 10.0134 12.5179 10.1108C12.2554 10.236 11.9335 10.1526 11.804 9.90211C11.6186 9.54731 11.4541 9.18207 11.2791 8.82379C11.1112 8.47247 10.9397 8.12463 10.7787 7.76983C10.7263 7.65852 10.6563 7.62025 10.5373 7.62025C10.0754 7.62025 10.0754 7.61678 10.0754 8.06897C10.0754 8.60117 10.0789 9.13685 10.0719 9.66905C10.0684 10.0099 9.90743 10.1595 9.5715 10.1491C9.28806 10.1421 9.14109 10.0065 9.13059 9.72123C9.1201 9.47774 9.12709 9.23425 9.12359 8.99076C9.12359 7.54025 9.12359 6.09322 9.12359 4.64271C9.12359 4.19051 9.29506 4.02007 9.74297 4.02007C10.1979 4.02007 10.6528 4.00616 11.1077 4.03051C11.9825 4.07225 12.6264 4.62532 12.7838 5.48449C12.9378 6.32628 12.5774 7.11588 11.818 7.47764C11.797 7.48807 11.7725 7.50199 11.7166 7.52981ZM10.0754 5.81842C10.0754 6.06191 10.0789 6.3054 10.0754 6.5489C10.0719 6.64977 10.1069 6.69499 10.2154 6.69499C10.5128 6.69151 10.8102 6.70543 11.1042 6.68803C11.5661 6.66368 11.8635 6.3228 11.867 5.83929C11.8705 5.34536 11.5766 4.99403 11.1112 4.96621C10.8102 4.94881 10.5058 4.96273 10.2049 4.95577C10.1069 4.95577 10.0719 4.99403 10.0719 5.09143C10.0789 5.33144 10.0754 5.57493 10.0754 5.81842Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M19.7075 7.07061C19.7075 6.2184 19.704 5.36618 19.7075 4.51396C19.7075 4.11742 19.9279 3.9435 20.3199 4.0235C20.5438 4.06872 20.6488 4.19742 20.6523 4.46179C20.6558 5.07747 20.6523 5.68967 20.6558 6.30536C20.6558 6.53841 20.6558 6.53841 20.8972 6.53841C21.3417 6.53841 21.7826 6.53493 22.227 6.54189C22.3669 6.54537 22.4089 6.50015 22.4054 6.36101C22.3984 5.75229 22.4019 5.14356 22.4054 4.53483C22.4089 4.10351 22.6504 3.92263 23.0668 4.03046C23.2278 4.0722 23.3187 4.17308 23.3397 4.33308C23.3537 4.42004 23.3572 4.50701 23.3572 4.59397C23.3572 6.2497 23.3572 7.90892 23.3572 9.56465C23.3572 9.59944 23.3572 9.63422 23.3537 9.66901C23.3327 10.0725 23.0073 10.2708 22.6329 10.1003C22.4369 10.0099 22.4054 9.83249 22.4054 9.64813C22.4019 9.01158 22.4054 8.37503 22.4019 7.73499C22.4019 7.45672 22.4334 7.47759 22.136 7.47759C21.7056 7.47759 21.2717 7.48107 20.8413 7.47411C20.6978 7.47063 20.6523 7.51237 20.6523 7.65847C20.6593 8.32981 20.6558 9.00115 20.6523 9.67596C20.6488 10.0064 20.4948 10.1525 20.1624 10.1456C19.8545 10.1386 19.711 9.99598 19.7075 9.67596C19.7075 8.80983 19.7075 7.94022 19.7075 7.07061Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M12.6377 14.8066C12.6377 15.2484 12.6237 15.6866 12.6412 16.1284C12.6762 17.1893 11.7769 18.0902 10.4927 17.8711C9.62836 17.7215 9.07898 17.085 9.06498 16.2154C9.04748 15.2553 9.04748 14.2918 9.06848 13.3283C9.08948 12.4726 9.78233 11.7908 10.6396 11.7282C11.399 11.6725 12.0149 11.916 12.4208 12.5873C12.5677 12.8308 12.6377 13.0987 12.6377 13.3839C12.6377 13.857 12.6377 14.3335 12.6377 14.8066ZM11.7034 14.7996C11.7034 14.3474 11.7069 13.8952 11.7034 13.4465C11.6999 12.9595 11.371 12.6465 10.8671 12.6395C10.3667 12.6326 10.0063 12.9317 9.99928 13.4117C9.98529 14.344 9.98529 15.2762 9.99928 16.2084C10.0063 16.7023 10.3562 16.998 10.8776 16.9876C11.3745 16.9806 11.6999 16.6641 11.7034 16.1736C11.7069 15.7145 11.7034 15.2553 11.7034 14.7996Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M5.86543 8.80283C5.61349 8.80283 5.36504 8.80631 5.11309 8.79936C4.99412 8.79588 4.94863 8.83414 4.95213 8.95936C4.95912 9.20981 4.95912 9.45678 4.95213 9.70723C4.94163 10.0238 4.79116 10.1594 4.47622 10.149C4.21378 10.1386 4.08081 10.0168 4.06331 9.75245C4.01782 9.01502 4.07031 8.29498 4.32226 7.5819C4.68618 6.56272 4.98362 5.51919 5.31255 4.48957C5.42803 4.12433 5.57499 4.00954 5.90393 4.01998C6.18037 4.02694 6.30984 4.11042 6.40082 4.37478C6.59678 4.96264 6.77874 5.55397 6.9677 6.14531C7.18816 6.83056 7.41911 7.50885 7.61857 8.19759C7.69205 8.44803 7.69905 8.72283 7.71305 8.98719C7.72704 9.24807 7.72005 9.50896 7.70255 9.76984C7.68505 10.0412 7.53458 10.1559 7.24414 10.1525C6.9747 10.149 6.82423 10.0203 6.81024 9.75593C6.79624 9.502 6.79624 9.2446 6.80674 8.99067C6.81374 8.84457 6.76475 8.79588 6.61778 8.80283C6.36583 8.80979 6.11738 8.80283 5.86543 8.80283ZM5.15508 7.86365C5.65548 7.86365 6.11738 7.86365 6.60728 7.86365C6.36583 7.10883 6.13488 6.37488 5.90043 5.63745C5.88643 5.63745 5.86893 5.63745 5.85494 5.64093C5.62398 6.37488 5.39653 7.10535 5.15508 7.86365Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M14.4333 7.06726C14.4333 6.63246 14.4298 6.19765 14.4333 5.76285C14.4403 4.63931 15.3606 3.85667 16.4804 4.02363C17.1837 4.12798 17.7016 4.46539 17.9186 5.16456C17.9676 5.32457 17.9711 5.50545 17.9641 5.67937C17.9571 5.86025 17.8416 5.97503 17.6596 6.0133C17.4217 6.06547 17.2572 6.0133 17.1557 5.8359C17.0962 5.73154 17.0543 5.61328 17.0193 5.49849C16.8968 5.08455 16.5119 4.86541 16.0255 4.93498C15.6371 4.99064 15.3676 5.3037 15.3676 5.71067C15.3641 6.62202 15.3641 7.5299 15.3676 8.44125C15.3676 8.84474 15.6266 9.15433 16.0045 9.21694C16.4944 9.29694 16.8513 9.11954 16.9983 8.71604C17.0263 8.63952 17.0403 8.55951 17.0648 8.48299C17.1767 8.15254 17.3167 8.07949 17.6841 8.15601C17.8626 8.19428 17.9396 8.34037 17.9536 8.51777C18.0026 9.16824 17.5826 9.77697 16.9108 10.0274C16.0745 10.337 15.1647 10.0692 14.7062 9.37695C14.5173 9.09171 14.4333 8.77518 14.4298 8.43777C14.4298 7.98209 14.4333 7.52294 14.4333 7.06726Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M22.3642 14.7996C22.3642 14.3579 22.3607 13.9196 22.3642 13.4778C22.3677 12.7161 22.7737 12.1108 23.4525 11.8499C24.1489 11.5786 25.0237 11.763 25.5066 12.2778C25.755 12.5421 25.881 12.8621 25.909 13.2169C25.93 13.5161 25.8145 13.6865 25.5626 13.7387C25.2931 13.7944 25.1182 13.6935 25.0062 13.4152C24.9887 13.3735 24.9782 13.3283 24.9677 13.283C24.8032 12.7091 24.1944 12.4621 23.673 12.7613C23.4385 12.8969 23.302 13.1056 23.302 13.3735C23.295 14.3231 23.295 15.2727 23.302 16.2258C23.3055 16.6084 23.589 16.8937 23.9949 16.9528C24.4463 17.0224 24.7997 16.8241 24.9362 16.4206C24.9747 16.3128 24.9957 16.198 25.0447 16.0901C25.1322 15.8884 25.2826 15.8223 25.5381 15.864C25.7585 15.8988 25.867 16.0136 25.8845 16.2327C25.916 16.6015 25.804 16.9319 25.5801 17.2241C24.8942 18.1285 23.1341 18.1528 22.5497 16.925C22.4377 16.6884 22.3887 16.4032 22.3712 16.1388C22.3432 15.6936 22.3642 15.2449 22.3642 14.7996Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M5.39998 15.1475C5.39998 14.3822 5.39648 13.6169 5.40347 12.8517C5.40347 12.7021 5.36498 12.6465 5.20752 12.6534C4.96257 12.6639 4.71762 12.6604 4.47267 12.6534C4.14024 12.643 4.00727 12.5143 4.00027 12.2047C3.99327 11.8777 4.12274 11.7177 4.44817 11.7142C5.39648 11.7038 6.34828 11.7073 7.29658 11.7177C7.59052 11.7212 7.73049 11.8916 7.73049 12.1943C7.73049 12.4899 7.59752 12.636 7.30008 12.6534C7.05163 12.6673 6.79968 12.6673 6.54773 12.6569C6.39027 12.6499 6.33778 12.6952 6.34128 12.8586C6.34828 14.3126 6.34478 15.7666 6.34478 17.2206C6.34478 17.2832 6.34478 17.3493 6.34478 17.4119C6.34128 17.8259 6.00885 18.0346 5.62393 17.8606C5.44896 17.7841 5.39998 17.6345 5.39998 17.4606C5.39998 16.7301 5.39648 15.9997 5.39648 15.2692C5.39998 15.2275 5.39998 15.1892 5.39998 15.1475Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M20.656 14.7997C20.656 15.6623 20.656 16.525 20.656 17.3911C20.656 17.8015 20.4426 17.9685 20.0367 17.892C19.8512 17.8572 19.7392 17.7424 19.7147 17.5511C19.7042 17.4711 19.7042 17.3876 19.7042 17.3076C19.7042 15.6345 19.7042 13.9579 19.7042 12.2847C19.7042 12.25 19.7042 12.2152 19.7042 12.1804C19.7147 11.8534 19.8547 11.7143 20.1766 11.7143C20.5021 11.7143 20.6525 11.8499 20.6525 12.1769C20.656 13.05 20.656 13.9266 20.656 14.7997Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                        <path d="M25.9964 7.08106C25.9964 7.8811 25.9964 8.68114 25.9964 9.4777C25.9964 9.54031 25.9964 9.6064 25.9964 9.66901C25.9789 10.0725 25.65 10.2777 25.279 10.0969C25.195 10.0551 25.1181 9.95772 25.0866 9.87076C25.0481 9.76641 25.0481 9.64467 25.0481 9.52988C25.0446 7.89501 25.0481 6.26363 25.0481 4.62876C25.0481 4.59398 25.0481 4.55919 25.0481 4.52441C25.0586 4.09656 25.3315 3.89829 25.7374 4.05134C25.8389 4.0896 25.9229 4.21483 25.9719 4.32266C26.0139 4.40266 25.9964 4.51397 25.9964 4.61485C25.9964 5.43576 25.9964 6.25667 25.9964 7.08106Z" stroke-width="0.4" mask="url(#path-1-outside-1)"></path>
                      </svg>
                    </use>
                  </svg>
                </a>
              </div>
              <!-- <div class="foot_proj_el architonic_logo">
                  <a href="https://www.architonic.com/en/microsite/wood-washi/3101389" target="_blank" title="architonic.com">
                      <img src="https://woodandwashi.com/local/templates/main/img/architonic-logo.png" alt="architonic.com">
                  </a>
              </div> -->
            </div>
          </div>
          <a href="https://woodandwashi.com/local/templates/main/files/fotobuch_f.pdf" target="_blank"
             class="photobook-ref">Photobook</a>
        </div>
      </div>
      <div class="foot_prava_bl">
        <div class="foot_prava_el">
          © 2021 WOOD &amp; WASHI ALL RIGHTS RESERVED
        </div>
        <div class="foot_prava_el">
          <a href="https://woodandwashi.com/privacy/" title="Privacy policy">Privacy policy</a></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style src="./footer.css" scoped>
</style>