<template>
  <div @click="editFolder" :style="{'opacity': opacity}" class="create-folder">
    <img src="../../../../assets/edit.svg" alt="">
  </div>
</template>

<script>
export default {
  name: 'EditFolderIcon',
  props: {
    opacity: {
      type: Number,
      required: false,
      default: 1,
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
  },
  methods: {
    editFolder() {
      this.$emit('open');
    },
  },
};
</script>

<style src="../../operations.scss" lang="scss">
</style>