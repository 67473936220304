<template>
  <div id="app">
    <router-view />
    <div class="version">{{ VERSION_DATA }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AUTH_URL } from "@/config";
import { date } from "@/../version.json";

export default {
  name: "App",
  computed: {
    ...mapState(["auth"]),
  },
  async created() {
    this.AUTH_URL = AUTH_URL;
    this.VERSION_DATA = date;
    await this.$store.dispatch("auth/checkInfoUser");
    /*  if (!this.auth.id) {
      window.location.href = `${this.AUTH_URL}`;
    } */
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #FFFFFF;
  position: relative;
}

.multiselect__tag {
  background: none;
}

.button-submit {
  background: #dabe96;
  height: 40px;
  width: 150px;
  border: 0;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;

  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #ffffff;
}
.version {
  position: absolute;
  bottom: 0px;
  right: 5px;
  opacity: 0.2;
  font-size: 8px;
  z-index: 999;
  &:hover {
    opacity: 1;
  }
}
</style>
