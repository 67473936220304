<template>
  <div class="drop-down-select">
    <div class="drop-down-select__input input" @click="toggle()" >
      <div v-if="content">
        <slot v-bind:option="content"></slot>
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div class="drop-down-select__options" :ref="refCustom" v-bind:class="{ opened: opened }">
      <div class="drop-down-select__option"
           v-if="empty"
           :key="empty"
           @click="handleChange(emptyOption)"
      >
        <slot v-bind:option="emptyOption"></slot>
      </div>
      <div class="drop-down-select__option"
           v-for="(option, index) in options"
           :key="index"
           @click="handleChange(option)"
           :class="[ content === option ? 'active' : '' ]"
      >
        <slot v-bind:option="option"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DropDownSelect',
  props: {
    refCustom: {
      String,
      required: false,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    value: [
      String,
      Number,
      Boolean,
      Object,
      Date,
      Symbol,
      Function,
    ],
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      emptyOption: {
        value: '',
        name: 'Все',
      },
      opened: false,
    };
  },
  computed: {
    content() {
      return this.options.find((option) => option.value === this.value);
    },
  },
  created() {
    document.addEventListener('click', this.clickOutside);
    this.$on('hook:beforeDestroy', () => document.removeEventListener('click', this.clickOutside));
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
      const { refCustom } = this;
      if (this.opened && refCustom) {
        // console.log(this.$refs[refCustom], 'ref');
        setTimeout(() => {
          this.$refs[refCustom].scrollIntoView({block: "end", behavior: "smooth"});
        }, 0)
      }
    },
    clickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.opened = false;
      }
    },
    handleChange(option) {
      this.opened = false;
      this.$emit('change', option.value);
    },
  },
};
</script>

<style src="./style.scss" lang="scss">
</style>
