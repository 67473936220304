<template>
  <div class="filters">
    <div class="filter">
      <span class="filter__label-dropdown"> System </span>
      <Multiselect
        multiple
        :value="system"
        :options="systemsOptions"
        @input="changeInput('system', $event)"
      />
    </div>
    <div class="filter">
      <span class="filter__label-dropdown"> Room </span>
      <Multiselect
        multiple
        :value="room"
        :options="roomsOptions"
        @input="changeInput('room', $event)"
      />
    </div>
    <div class="filter">
      <span class="filter__label-dropdown"> Collection </span>
      <Multiselect
        multiple
        :value="collection"
        :options="collectionsOptions"
        @input="changeInput('collection', $event)"
      />
    </div>
    <div class="filter">
      <span class="filter__label-dropdown"> Material </span>
      <Multiselect
        multiple
        :value="material"
        :options="materialsOptions"
        @input="changeInput('material', $event)"
      />
    </div>
  </div>
</template>

<script>
import { api } from "@/api";
import { Library } from "@/mixins/mixins";
import Multiselect from "@/components/drop-down-multi/Multiselect";

export default {
  name: "EditFilters",
  components: { Multiselect },
  props: {
    fullPathProp: {
      type: [String, Array],
      default: "",
      required: false,
    },
    multiSelectedFiles: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      system: [],
      room: [],
      collection: [],
      material: [],
      systemsOptions: [],
      roomsOptions: [],
      materialsOptions: [],
      collectionsOptions: [],
    };
  },
  mixins: [Library],
  methods: {
    changeInput(key, value) {
      console.log("fullPathProp: ", this.fullPathProp);
      this[key] = value;
      const filtersTags = [].concat(
        this.system,
        this.room,
        this.collection,
        this.material
      );

      const filtersTagsIds = filtersTags.map((tag) => {
        return tag.id;
      });

      const keysValue =
        this.multiSelectedFiles.length > 1
          ? [...this.multiSelectedFiles]
          : [this.fullPathProp];
      //typeof this.fullPathProp === 'string' ? [this.fullPathProp] : [...this.fullPathProp]
      api
        // .get(`/api/s3/object/tag/setTags=${this.fullPath}&type=${type}&tag=${tag}`)
        .post(
          "/api/s3/setTags",
          JSON.stringify({
            keys: keysValue,
            tags: filtersTagsIds,
          })
        )
        .then(() => {
          this.fetchAPIData();
        })
        .catch((err) => {
          console.log(
            "The API is facing issues. Please try again later.n" + err
          );
        });

      /*if (this.system !== '-')
      saveTag(1, this.system);*/
      // if (this.room !== '-')
      //   saveTag(2, this.room);
      // if (this.collection !== '-')
      //   saveTag(3, this.collection);
      // if (this.material !== '-')
      //   saveTag(4, this.material);
      // this.fetchAPIData();
    },
    // createCollectionsOptions(val) {
    //   const result = [];
    //   val.map(el => {
    //     return result.push(el.Tag)
    //   })
    //   this.collectionsOptions = result;
    // },
    // createMaterialsOptions(val) {
    //   const result = [];
    //   val.map(el => {
    //     return result.push(el.Tag)
    //   })
    //   this.materialsOptions = result;
    // },
    // createRoomsOptions(val) {
    //   const result = [];
    //   val.map(el => {
    //     return result.push(el.Tag)
    //   })
    //   this.roomsOptions = result;
    // },
    // createSystemsOptions(val) {
    //   const result = [];
    //   val.map(el => {
    //     return result.push(el.Tag)
    //   })
    //   this.systemsOptions = result;
    // },
  },
  mounted() {
    api
      .get(`/api/s3/tag`)
      .then((response) => {
        // this.createCollectionsOptions(response.collection);
        // this.createMaterialsOptions(response.material);
        // this.createRoomsOptions(response.room);
        // this.createSystemsOptions(response.system);
        this.materialsOptions = response.material;
        this.collectionsOptions = response.collection;
        this.roomsOptions = response.room;
        this.systemsOptions = response.system;
      })
      .catch((err) => {
        console.log("The API is facing issues. Please try again later.n" + err);
      });
    api
      .get(`/api/s3/object/tag?key=${this.fullPathProp}`)
      .then((response) => {
        for (const e of response) {
          // console.log(e);
          // tags[t.id] = t.Tag;
          switch (e.type) {
            case 1:
              this.system.push(e);
              console.log("system: ", this.system);
              break;
            case 2:
              this.room.push(e);
              break;
            case 3:
              this.collection.push(e);
              break;
            case 4:
              this.material.push(e);
              break;
          }
        }
      })
      .catch((err) => {
        console.log("The API is facing issues. Please try again later.n" + err);
      });
  },
  computed: {},
};
</script>

<style src="../style.scss" lang="scss"></style>

<style scoped lang="scss">
.drop-down-select {
  width: 140px;
  padding: 5px 0;
  border: 1px solid #afafaf;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #dabe96;
  }
}
</style>
