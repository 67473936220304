<template>
  <button
    :class="{ active: item.value === selectedCategoryValue }"
    class="category-faq"
    @click="setSelectedCategoryValue(item.value)"
  >
    {{ item.name }}
  </button>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      selectedCategoryValue: (state) => state.faq.selectedCategoryValue
    })
  },
  methods: {
    ...mapActions({
      setSelectedCategoryValue: 'faq/setSelectedCategoryValue'
    })
  }
};
</script>

<style lang="scss" scoped>
.category-faq {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 24px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: var(--dark-gray);
  background-color: var(--white);
  cursor: pointer;

  &.active {
    color: var(--gold);
  }
}
</style>
