<template>
  <input class="filters__text-input"
         :type="type"
         :placeholder="placeholder"
         :value="value"
         @change="debounce ? dChange($event) : change($event)"
  />
</template>

<script>
import debounce from 'lodash-es/debounce';

export default {
  name: 'TextInput',
  props: {
    placeholder: {
      type: String,
    },
    value: {
      default: null,
      required: false,
    },
    type: {
      default: 'text',
      type: String,
    },
    debounce: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(e) {
      const value = String(e.target.value);

      if (this.value !== value) {
        this.$emit('change', value);
      }
      this.$forceUpdate();
    },
    // eslint-disable-next-line func-names
    dChange: debounce(function (e) {
      this.change(e);
    }, 300),
  },
};
</script>
