<template>
  <div class="faq-item" :class="{ open: item.isOpen }">
    <button class="faq-item__head" @click="setItemOpen(item.id)">
      {{ item.question }}
    </button>
    <div class="faq-item__body">{{ item.answer }}</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      setItemOpen: 'faq/setItemOpen'
    })
  }
};
</script>

<style lang="scss" scoped>
.faq-item {
  border-bottom: 1px solid var(--light-gray);

  // .faq-item__head
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    min-height: 70px;
    width: 100%;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: var(--dark-gray);
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      color: var(--gold);
    }

    &::after {
      content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMS4zMzRsOCA4IDgtOCIgc3Ryb2tlPSIjNDQ0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==');
      transition: all 0.3s ease-in;
      perspective: 50px;
    }
  }
  // .faq-item__body
  &__body {
    display: none;
    padding: 0 24px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: var(--dark-gray);
  }

  &.open {
    .faq-item__head {
      &::after {
        transform: rotateX(180deg);
      }
    }
    .faq-item__body {
      display: block;
    }
  }
}
</style>
