import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import appCtx from "./modules/appCtx";
import createPersistedState from "vuex-persistedstate";
import library from "@/store/modules/library";
import files from "@/store/modules/files";
import folders from "@/store/modules/folders";
import groups from "@/store/modules/groups";
import prefixSelected from "@/store/modules/prefixSelected";
import filters from "@/store/modules/filters";
import user from "@/store/modules/user";
import faq from "@/store/modules/faq";

Vue.use(Vuex);

const filtersData = createPersistedState({
  key: "filters",
  paths: ["filters"],
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [filtersData],
  modules: {
    auth,
    appCtx,
    library,
    files,
    folders,
    groups,
    prefixSelected,
    user,
    filters,
    faq,
  },
});
