<template>
  <div @click="createFolder" :style="{'opacity': opacity}" class="create-folder">
    <img src="../../../../assets/folder.svg" alt="">
  </div>
</template>

<script>
export default {
  name: 'AddFolderIcon',
  props: {
    opacity: {
      type: Number,
      required: false,
      default: 1
    },
  },
  methods: {
    createFolder() {
      this.$emit('create');
    }
  }
};
</script>

<style src="../../operations.scss" lang="scss">
</style>