<template>
  <TheModal title="New folder" @close="closeModal">
    <div class="add-folder">
      <div class="add-folder__confirm">
        <form
          enctype="form-data"
          novalidate
          class="add-folder__body"
          style="padding: 20px"
        >
          <div class="add-folder__input-container">
            <label class="add-folder__label" for="add-folder"> Folder </label>
            <input
              id="add-folder"
              class="add-folder__input"
              type="text"
              v-model="folderName"
            />
          </div>
          <button type="button" class="button" @click="addFolder">Done</button>
        </form>
      </div>
    </div>
  </TheModal>
</template>

<script>
import { api } from "@/api";
import { mapState } from "vuex";
import { Library } from "@/mixins/mixins";
import TheModal from "@/components/modal/TheModal";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "AddFolder",
  components: { TheModal },
  props: {
    prefix: {
      type: String,
      required: false,
      default: "",
    },
    closeModal: {
      type: Function,
    },
  },
  mixins: [Library],
  data() {
    return {
      currentStatus: null,
    };
  },
  computed: {
    ...mapState(["folders"]),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    addFilters() {
      this.$emit("add-filters");
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
    },
    addFolder() {
      this.currentStatus = STATUS_SUCCESS;
      if (this.folderName === "") return;
      api
        .post(`/api/s3/folder?folderpath=${this.prefix}${this.folderName}`)
        .then(() => {
          this.$store.dispatch("folders/addedItem", {
            Prefix: `${this.prefix}${this.folderName}/`,
            thumb: "",
          });
          this.addFilters();
        })
        .catch((err) => {
          console.log(
            "The API is facing issues. Please try again later.n" + err
          );
        });
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style src="../../operations.scss" lang="scss"></style>
