// import { api } from "@/api";

import set from "lodash-es/set";
import cloneDeep from "lodash-es/cloneDeep";

export default {
    namespaced: true,
    state: {
        name: '',
        size: '',
        path: '',
        modified: '',
        orig: '',
        thumb: '',
        items: [],
        fileIndex: null,
    },
    mutations: {
        setAll(state, items) {
            state.items = items;
        },
        setCurrentProperty(state, { path, value }) {
            set(state, path, value);
            state = cloneDeep(state);
        },
        info(state, addData) {
            state.name = addData.name;
            state.size = addData.size;
            state.path = addData.path;
            state.modified = addData.modified;
            state.orig = addData.orig;
            state.thumb = addData.thumb;
            state.fileIndex = addData.fileIndex;
        },
        add(state, data) {
            state.items.push(data);
        },
        remove(state, i) {
            state.items = state.items.filter((item, index) => {
                if (index === i) {
                    return false;
                }
                return true;
            });
        },
    },
    actions: {
        setItems({ commit }, data) {
            commit('setAll', data);
        },
        setCurrentProperty({ commit }, { path, value }) {
            commit('setCurrentProperty', { path, value });
        },
        getInfo({ commit }, addData) {
            commit('info', addData);
        },
        addedItem({ commit }, item) {
            commit('add', {
                quantity: 1,
                item,
            });
        },
        removedItem({ commit }, index) {
            commit('remove', index);
        },
    },
};
