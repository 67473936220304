export default {
  namespaced: true,
  state: {
    faqOpen: false,
    selectedCategoryValue: 'all',
    searchQuery: '',
    faqs: [],
    categories: [],
    scriptLoaded: false // feedback script
  },
  mutations: {
    mutFaqOpen(state, payload) {
      state.faqOpen = payload;
    },
    mutItemOpen(state, id) {
      state.faqs.forEach((item) => {
        if (item.id === id) {
          item.isOpen = !item.isOpen;
        }
      });
    },
    mutSelectedCategoryValue(state, value) {
      state.selectedCategoryValue = value;
    },
    mutSearchQuery(state, value) {
      state.searchQuery = value;
    },
    mutSetFaqs(state, data) {
      state.faqs = data.map((item) => {
        item.isOpen = false;
        return item;
      });
    },
    mutSetScriptLoaded(state, value) {
      state.scriptLoaded = value;
    },
    mutSetCategories(state, data) {
      state.categories = data;
    }
  },
  actions: {
    setFaqOpen({ commit }, input) {
      commit('mutFaqOpen', input);
    },
    setItemOpen({ commit }, id) {
      commit('mutItemOpen', id);
    },
    setSelectedCategoryValue({ commit }, value) {
      commit('mutSelectedCategoryValue', value);
    },
    setSearchQuery({ commit }, input) {
      commit('mutSearchQuery', input);
    },
    setFaqs({ commit }, data) {
      commit('mutSetFaqs', data);
    },
    setScriptLoaded({ commit }, value) {
      commit('mutSetScriptLoaded', value);
    },
    setCategories({ commit }, data) {
      commit('mutSetCategories', data);
    }
  },
  getters: {
    sortedFaqs(state) {
      if (state.selectedCategoryValue === 'all') return state.faqs;
      return state.faqs.filter((item) => item.type === state.selectedCategoryValue);
    },
    sortedAndSearchedFaqs(state, getters) {
      return getters.sortedFaqs.filter((item) =>
        item.question.toLowerCase().includes(state.searchQuery.toLowerCase())
      );
    }
  }
};
