<template>
  <div @click="openLink" class="link" :style="{'opacity': opacity}">
    <img src="../../assets/link.svg" alt="">
  </div>
</template>

<script>
import { Library } from '@/mixins/mixins';

export default {
  name: 'Link',
  mixins: [Library],
  methods: {
    openLink() {
      this.$emit('open-link');
    }
  },
  props: {
    opacity: {
      type: Number,
      required: false,
    },
  },
}
</script>

<style src="./operations.scss" lang="scss">
</style>