import { api } from '@/api';
import set from 'lodash-es/set';
import cloneDeep from 'lodash-es/cloneDeep';

export default {
  namespaced: true,
  state: {
    agent: '',
    fname: '',
    sname: '',
    email: '',
    wphone: '',
    mphone: '',
    password: '',
    maxdiscount: 0,
    acctype: 'manager',
    error: false,
  },
  mutations: {
    saveData(state, userData) {
      state.agent = userData.agent;
      state.fname = userData.fname;
      state.sname = userData.sname;
      state.email = userData.email;
      state.wphone = userData.wphone;
      state.mphone = userData.mphone;
      state.password = userData.password;
      state.maxdiscount = userData.maxdiscount;
      state.error = false;
    },
    postData(state, { path, value }) {
      set(state, path, value);
      state = cloneDeep(state);
    },
    getData(state, userData) {
      state.agent = userData.agent;
      state.fname = userData.fname;
      state.sname = userData.sname;
      state.email = userData.email;
      state.wphone = userData.wphone;
      state.mphone = userData.mphone;
      state.maxdiscount = userData.maxdiscount;
      state.acctype = userData.acctype;
      state.error = false;
    },
    error(state) {
      state.error = true;
    },
  },
  actions: {
    saveData({ commit }, userInfo) {
      api.post(`/users`, {
        agent: userInfo.agent,
        fname: userInfo.fname,
        sname: userInfo.sname,
        email: userInfo.email,
        wphone: userInfo.wphone,
        mphone: userInfo.mphone,
        maxdiscount: 0,
        password: userInfo.password
      })
        .then(() => {
          commit('saveData');
        })
        .catch(() => {
          commit('error');
        });
    },
    postData({ commit }, { path, value }) {
      commit('postData', { path, value });
    },
    getData({ commit }, userInfo) {
      api.get(`/users/${userInfo.userId}`)
        .then((res) => {
          commit('getData', {
            agent: res.agent,
            fname: res.fname,
            sname: res.sname,
            email: res.email,
            wphone: res.wphone,
            mphone: res.mphone,
            maxdiscount: res.maxdiscount,
            acctype: res.acctype,
          });
        })
        .catch(() => {
          commit('error');
        });
    },
  },
}