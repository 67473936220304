<template>
  <div class="input__container"
       :style="{ 'width': width + 'px'}"
  >
    <span class="input__label">
      {{  name  }}
    </span>
    <input class="input__value"
           :type="type"
           :placeholder="placeholder"
           :value="value"
           @change="debounce ? dChange($event) : change($event)"
    />
  </div>
</template>

<script>
import debounce from 'lodash-es/debounce';

export default {
  name: 'NewTextInput',
  props: {
    width: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    name: {
      default: '',
      type: String,
      required: true,
    },
    value: {
      default: null,
      required: false,
    },
    type: {
      default: 'text',
      type: String,
    },
    debounce: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /*    showLabel() {
      return this.value ? this.name : '';
    }, */
  },
  methods: {
    change(e) {
      const value = String(e.target.value);

      if (this.value !== value) {
        this.$emit('change', value);
      }
      this.$forceUpdate();
    },
    // eslint-disable-next-line func-names
    dChange: debounce(function (e) {
      this.change(e);
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.input {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100px;
    position: relative;
    margin-bottom: 20px;
  }

  &__label {
    position: absolute;
    // position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    color: gray;
    background: white;
    font-size: 10px;
    padding: 0 7px;
    text-transform: lowercase;
    white-space: pre;
    // margin: 0 auto;
  }

  &__value {
    outline: none;
    padding: 10px;
    text-align: center;
    border: 1px solid #DEDEDE;
    // margin: 10px 0;

    &:active,
    &:focus {
      border: 1px solid #DABE96;
    }

    &::placeholder {
      font-size: 12px;
      color: #AFAFAF;
    }
  }
}

</style>