import { api } from "@/api";
import { mapState } from "vuex";

export const FiltersMixin = {
  data() {
    return {
      users: [],
      userId: 0,
      access: {},
      permission: {},
      editPermission: {},
      permissionId: null,
      collectionCheck: [
        "Rollo",
        "Panel",
        "Banner",
        "Flow",
        "Slides",
        "Washi Unique",
      ],
    };
  },
  methods: {
    setAccess(id, access) {
      this.collectionCheck.map((el) => {
        if (
          this.multiSelectedPrefix.length > 1 &&
          el === this.multiSelectedPrefix[0]
        ) {
          this.multiSelectedPrefix = this.multiSelectedPrefix.slice(1);
        }
      });
      api
        .post(
          `/api/s3/access`,
          JSON.stringify({
            uid: id || this.permissionId,
            key: this.multiSelectedPrefix,
            access:
              this.permission[id] ||
              this.permission[this.permissionId] ||
              access,
          })
        )
        .then(() => {
          this.editPermission = false;
        })
        .catch((err) => {
          console.log(
            "The API is facing issues. Please try again later.n" + err
          );
        });
    },
  },
};

export const Library = {
  data() {
    return {
      folderName: "",
      fullPath: "",
      items: [],
      selected: null,
      errorImage: {},
      selectedPrefix: null,
      prefix: "",
      // delimter: '/',
      isLoading: false,
      fullPage: true,
      uploadForm: false,
      addFolderForm: false,
      modalFilters: false,
      detailImage: false,
      preview: "",
      modalLinks: false,
      modalUser: false,
      editFolder: false,
      multiSelectedFile: {},
      multiSelectedFilePrefix: [],
      multiSelected: {},
      multiSelectedPrefix: [],
      isHome: false,
      nextPage: false,
      system: "",
      room: "",
      collection: "",
      material: "",
      breadCrumbs: "",
    };
  },
  computed: {
    ...mapState(["filters", "library"]),
  },
  methods: {
    resetData() {
      this.selected = null;
      this.detailImage = false;
      this.editFolder = false;
      this.folderName = "";
      this.fullPath = "";
      this.multiSelected = {};
      this.multiSelectedFile = {};
      this.multiSelectedPrefix = [];
      this.multiSelectedFilePrefix = [];
      // this.errorImage = {};

      this.$store.commit("folders/setFolderIndex", null);
    },
    fetchAPIData() {
      this.addFolderForm = false;
      this.uploadForm = false;

      this.$store.commit("library/loading", true);

      // this.system = this.filters.system;
      // this.room = this.filters.room;
      // this.collection = this.filters.collection;
      // this.material = this.filters.material;

      const filtersTags = [].concat(
        this.filters.system,
        this.filters.room,
        this.filters.collection,
        this.filters.material
      );

      const filtersTagsIds = filtersTags
        .map((tag) => {
          return tag.id;
        })
        .join(",");

      const pref = this.filters.prefix || this.prefix;
      // console.log('pref', pref);
      this.$store.dispatch("prefixSelected/setPref", pref);

      api
        .get(
          `/api/s3/find?&prefix=${
            this.filters.prefix || this.prefix
          }&filtersTags=${filtersTagsIds}`
        )
        .then((response) => {
          //debugger;
          this.isHome = this.filters.prefix === "";
          this.$store.commit("library/home", this.isHome);

          let folders = [];

          const files = response[0].Contents.filter(function (item) {
            return item.Key.charAt(item.Key.length - 1) !== "/";
          });

          if (this.system.length > 1 && response.length > 1) {
            this.$store.commit("folders/setMultipleFolders", true);

            response.map((el) => {
              folders = folders.concat(el.CommonPrefixes);
            });
          }
          if (this.system.length > 1 && this.fullPath) {
            folders = response[0].CommonPrefixes;
            this.nextPage = true;
          }

          if (this.nextPage) {
            this.fullPath = "";
          }

          if (this.system.length <= 1) {
            // console.log('commonPrefixes', response[0].CommonPrefixes);
            folders = response[0].CommonPrefixes;
            this.$store.commit("folders/setMultipleFolders", false);
          }

          // if (this.system < 2) this.$store.commit('folders/setMultipleFolders', false);

          const folderState = folders.filter(function (item) {
            return item.Prefix.indexOf("/") !== -1;
          });

          if (folderState) {
            // console.log('folderState', folderState);
            this.$store.dispatch("folders/setItems", folderState);
          }

          if (files) {
            // console.log('files', files);
            this.$store.dispatch("files/setItems", files);
          }

          let groups = [];
          let groupState = [];
          if (response[0].Groups) {
            groups = response[0].Groups;
            groupState = groups.filter(function (item) {
              return item.Prefix.indexOf("/") !== -1;
            });
          }

          if (groupState) {
            // console.log('groupState', groupState);
            this.$store.dispatch("groups/setItems", groupState);
          }

          this.$store.commit("library/loading", false);
        })
        .catch((err) => {
          console.log(
            "The API is facing issues. Please try again later.n" + err
          );
          this.$store.commit("library/loading", false);
        });
    },
  },
};
