<template>
  <div class="detail">
    <div v-if="files.name.includes('mp4')">
      <img
        class="detail__image"
        src="../../../assets/video_preview.png"
        :alt="files.name"
      />
    </div>
    <div v-else>
      <img
        class="detail__image"
        :src="files.thumb"
        :alt="files.name"
        @error="errorHandler($event)"
      />
    </div>
    <div class="detail__info">
      <div>Name: {{ files.name }}</div>
      <div>Size: {{ files.size }} кб</div>
      <div>Modified: {{ files.modified }}</div>
      <!--      <div>Скачан: 40 раз</div>
            <div>Вирусов не обнаружено</div>-->
      <button class="button" @click="downloadFile">Open original</button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { api } from "@/api";
import { Library } from "@/mixins/mixins";

export default {
  name: "Detail",
  computed: {
    ...mapState(["files", "library"]),
  },
  mixins: [Library],
  methods: {
    downloadFile() {
      this.$store.commit("library/loading", true);
      api
        .downloadFile(`/api/s3/file?filename=${this.files.path}`)
        .then(({ data: fileBytes }) => {
          const url = window.URL.createObjectURL(new Blob([fileBytes]));
          console.log("url", url);
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", this.files.name);
          link.click();

          this.$store.commit("library/loading", false);
        })
        .catch((err) => {
          console.log(
            "The API is facing issues. Please try again later.n" + err
          );
          this.$store.commit("library/loading", false);
        });
    },
    errorHandler(e) {
      const images = require.context("../../../assets/", false);
      e.target.src = images("./no_preview_2.png");
    },
  },
};
</script>

<style scoped lang="scss">
.detail {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 70px;
  color: #191919;

  &__image {
    padding-right: 50px;
    // min-width: 400px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div {
      padding: 10px 0;
    }
  }

  .detail__image {
    width: 225px;
  }
}

@media (max-width: 499px) {
  .detail {
    flex-direction: column;

    &__image {
      max-width: 100%;
    }
  }
}
</style>
