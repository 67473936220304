<template>
  <div class="zam-container">
    <div class="zam-container__body">
      <!-- <template v-show="scriptLoaded"> -->
      <div v-show="scriptLoaded" class="zam-container__content" @submit="closeModalWithTimeout()">
        <h2 v-show="showField" class="zam-container__title">Feedback form</h2>
        <h3 v-show="showField" class="zam-container__sub-title">Leave your contact details, our specialist will answer as soon as possible</h3>
        <!-- контейнер для zammad -->
        <div id="zammad-feedback-form"></div>
        <div v-show="showField" class="zam-container__personal">By clicking the "Submit" button you agree to the processing of personal data</div>
      </div>
      <button class="zam-container__close" type="button" @click="$emit('closeFeedback')">
        <FaqCloseIcon></FaqCloseIcon>
      </button>
      <!-- </template> -->
      <div v-show="!scriptLoaded" class="zam-container__thanks">Loading</div>
    </div>
  </div>
</template>

<script>
import FaqCloseIcon from '@/components/faq/icon/FaqCloseIcon.vue';
import { mapActions, mapState } from 'vuex';
export default {
  components: { FaqCloseIcon },
  data() {
    return {
      showField: true
    };
  },
  computed: {
    ...mapState({
      scriptLoaded: (state) => state.faq.scriptLoaded
    })
  },
  created() {
    if (this.scriptLoaded) {
      this.initForm();
      return;
    }
    // сначала загружаем jquery, т.к того требует helpdesk
    // затем загружаем исполняющий скрипт helpdesk
    // после загрузки запускаем
    new Promise((res) => {
      const script = document.createElement('script');
      script.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
      document.body.append(script);
      script.addEventListener('load', res, { once: true });
    }).then(() => {
      const script = document.createElement('script');
      script.id = 'zammad_form_script';
      script.src = 'https://ts.devkeeper.ru/assets/form/form.js';
      document.body.append(script);
      script.addEventListener(
        'load',
        () => {
          this.initForm();
          this.setScriptLoaded(true);
        },
        { once: true }
      );
    });
  },
  methods: {
    ...mapActions({
      setScriptLoaded: 'faq/setScriptLoaded'
    }),
    closeModalWithTimeout() {
      this.showField = false;
      const t = setTimeout(() => {
        this.$emit('closeFeedback');
        this.showField = true;
        clearTimeout(t);
      }, 3000);
    },
    initForm() {
      // const i18n = this.$i18n;

      // eslint-disable-next-line no-undef
      $(function () {
        // eslint-disable-next-line no-undef
        $('#zammad-feedback-form').ZammadForm({
          lang: 'en', // i18n.locale
          messageTitle: 'Contact with us', // название в тикетах (не на фронте)
          messageSubmit: 'Submit',
          messageThankYou: 'Thank you for contacting us.',
          noCss: true
          // attachmentSupport: true // загрузка файла
        });
      });
    }
  }
};
</script>

<style lang="scss">
.zam-container {
  * {
    transition: none !important;
  }
  --z-index: 100;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index);
  background-color: rgba(0, 0, 0, 0.5);
  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 100%;
    padding: 48px 0;
    background-color: var(--white);
    z-index: calc(var(--z-index) + 1);
  }
  &__content {
    max-width: 400px;
    text-align: center;
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    color: var(--gray);
    z-index: calc(var(--z-index) + 2);
  }
  &__title {
    margin: 0px 0px 20px 0px;
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-gray);
  }
  &__sub-title {
    margin: 0px 0px 32px 0px;
    font-size: 16px;
    font-weight: 400;
    color: #333f48;
  }
  &__personal {
    font-size: 14px;
    font-weight: 400;
    color: #333f48;
  }
  &__thanks {
    @extend .zam-container__content;
    font-size: 16px;
    font-weight: 400;
  }
}
.form-group.zammad-form-group {
  margin: 0px 0px 24px 0px;
  &:nth-last-child(2) {
    margin: 0px 0px 32px 0px;
  }
  label {
    display: none;
  }
  input,
  textarea {
    font-size: 16px;
    font-weight: 400;
    color: var(--dark-gray);
    border: 1px solid var(--light-gray);
    caret-color: var(--gold);
    &::placeholder {
      color: var(--gray);
    }
  }
  input {
    min-height: 40px;
    padding: 0 12px;
  }
  textarea {
    min-height: 80px;
    max-height: 300px;
    padding: 9px 12px;
    resize: vertical;
  }
}
.zammad-form {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    padding: 0 12px;
    margin-bottom: 20px;
    border: none;
    background-color: var(--gold);
    color: var(--white);
    text-transform: uppercase;
    cursor: pointer;
  }
  .zammad-form-thankyou {
    font-size: 16px;
    font-weight: 400;
  }
}
.form-control {
}
.zammad-form-control {
}
.btn {
}
</style>
