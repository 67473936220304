<template>
  <div>
    <a @click="downloadFile" class="download" :style="{ opacity: opacity }">
      <img src="../../assets/download.svg" alt="" />
      <p>DOWNLOAD FILE</p>
    </a>
  </div>
</template>

<script>
import { api } from "@/api";
import { mapState } from "vuex";
import { Library } from "@/mixins/mixins";

export default {
  name: "Download",
  mixins: [Library],
  props: {
    opacity: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState(["files", "library"]),
  },

  methods: {
    downloadFile() {
      if (this.files.path) {
        this.$store.commit("library/loading", true);

        api
          .downloadFile(`/api/s3/file?filename=${this.files.path}`)
          .then(({ data: fileBytes }) => {
            const url = window.URL.createObjectURL(new Blob([fileBytes]));
            console.log("url", url);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", this.files.name);
            link.click();

            this.$store.commit("library/loading", false);
          })
          .catch((err) => {
            console.log(
              "The API is facing issues. Please try again later.n" + err
            );
            this.$store.commit("library/loading", false);
          });
      }
    },
  },
};
</script>

<style src="./operations.scss" lang="scss"></style>
