import Vue from 'vue'
import VueRouter from 'vue-router'
import Certificate from "@/components/sertificate/Certificate";
import Library from "@/components/library/Library";

Vue.use(VueRouter)

const routes = [
  {
    path: '/certificate',
    name: 'certificate',
    component: Certificate,
  },
  {
    path: '/',
    name: 'library',
    component: Library,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
