<template>
  <div class="topbar-container">
    <div class="topbar">
      <router-link to="/">
        <img src="./../../assets/logo.svg" alt="logo" />
      </router-link>
      <div class="logout">
        <button class="faq-button" @click="setFaqOpen(!faqOpen)">help me</button>
          <span class="mobile-filter" @click="showModalFilters">
            <img src="../../assets/filter.svg" />
          </span>
        <a @click="logout"><img alt="logout" src="../../assets/logout.svg" /></a>
      </div>
    </div>
    <div class="topbar-additional">
      <NavigateFilters :folderPath="prefix"/>
      <Search />
    </div>
    <TheModal v-if="filters.modal" @close="closeModalFilters">
      <NavigateFilters :folderPath="prefix"/>
    </TheModal>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Search from "@/components/search/Search";
import NavigateFilters from "@/components/filters/navigate/NavigateFilters";
import {AUTH_URL} from '@/config';
import TheModal from '@/components/modal/TheModal';

export default {
  name: 'TopBar',
  components: {TheModal, NavigateFilters, Search},
  props: {
    prefix: {
      type: String,
      required: false,
      default: ''
    },
  },
  computed: {
    ...mapState([
      'auth',
      'filters',
    ]),
    ...mapState({
      faqOpen: (state) => state.faq.faqOpen
    }),
  },
  created() {
    this.AUTH_URL = AUTH_URL;
  },
  methods: {
    ...mapActions({
      setFaqOpen: 'faq/setFaqOpen'
    }),
    showModalFilters() {
      this.$store.commit('filters/showModal', true);
    },
    closeModalFilters() {
      this.$store.commit('filters/showModal', false);
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.commit('filters/clearFilters');
      window.location.href = `${this.AUTH_URL}`;
    },
  },
};
</script>

<style src="./layout.scss" lang="scss"></style>
<style lang="scss">
.faq-button {
  margin-right: 20px;
  color: var(--accent-brown);
  cursor: pointer;
  border: none;
  white-space: nowrap;
  text-decoration: underline;
  text-transform: uppercase;
  background-color: transparent;
}
</style>