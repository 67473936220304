export default {
    namespaced: true,
    state: {
        error: false,
        isLoading: false,
        isHome: false,
    },
    mutations: {
        error(state) {
            state.error = true;
        },
        loading(state, bool) {
            state.isLoading = bool;
        },
        home(state, bool) {
            state.isHome = bool;
        },
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        addfolder({ commit }, addData) {
            commit('error', addData);
        },
    },
};
