import axios from "axios";
import { AUTH_URL } from "@/config";
export class HttpClient {
  _client;
  _baseURL;
  constructor(baseURL) {
    this._baseURL = baseURL;
    const config = {
      baseURL,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };
    this._client = axios.create(config);
    this._client.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && error.response.status == 401) {
          window.location.href = AUTH_URL;
        }
        return Promise.reject(error);
      }
    );
  }

  setHeaders(cookie) {
    if (cookie) {
      this._client.defaults.headers.Cookie = cookie;
    }
  }

  setBearer(authToken) {
    this._client.defaults.headers.Authorization = `Bearer ${authToken}`;
  }

  clearBearer() {
    delete this._client.defaults.headers.Authorization;
  }

  getBaseUrl() {
    return this._baseURL;
  }

  async get(url, data = null, cookie = null) {
    console.log(`get cookie`, cookie);
    console.log(`get url`, url);
    return this._client
      .get(url, { params: data })
      .then((response) => response.data);
  }
  async post(url, data = null) {
    return this._client.post(url, data).then((response) => response.data);
  }
  put(url, data = null) {
    return this._client.put(url, data);
  }
  patch(url, data = null) {
    return this._client.patch(url, data);
  }
  delete(url, data = null) {
    return this._client.delete(url, { params: data });
  }
  downloadFile(url, downloadProgress) {
    return this._client.get(url, {
      responseType: "blob",
      onDownloadProgress: downloadProgress,
    });
  }
}
export default class FetchClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.headers = {
      Accept: "application/json",
      withCredentials: true,
      credentials: "include",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    };
  }

  setBearer(authToken) {
    this.headers.Authorization = `Bearer ${authToken}`;
  }

  clearBearer() {
    delete this.headers.Authorization;
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  async fetch(method, url, body) {
    const r = await fetch(`${this.baseUrl}${url}`, {
      headers: this.headers,
      method,
      body,
      // mode: 'no-cors',
    });
    return r.json();
  }

  async fetch2(method, url, body) {
    const r = await fetch(`${this.baseUrl}${url}`, {
      headers: this.headers,
      method,
      body,
      // mode: 'no-cors',
    });
    return r;
  }

  async fetchForPostFile(method, url, body) {
    const file = body.get("file");
    const headers = {
      Authorization: this.headers.Authorization,
      "Content-Length": file.length,
      // 'Content-Type': 'multipart/form-data'
    };
    const r = await fetch(`${this.baseUrl}${url}`, {
      headers: headers,
      method,
      body,
      // mode: 'no-cors',
    });
    return r;
  }

  async fetch3(method, url, body) {
    const headers = {
      Authorization: this.headers.Authorization,
      "Content-Type": "multipart/form-data",
    };
    const r = await fetch(`${this.baseUrl}${url}`, {
      headers: headers,
      method,
      body,
      // mode: 'no-cors',
    });
    return r;
  }

  async get(url) {
    return this.fetch("GET", url);
  }

  async download(url) {
    return this.fetch3("GET", url);
  }

  async getFile(url) {
    return this.fetch2("GET", url);
  }

  async postFile(url, body) {
    return this.fetchForPostFile("POST", url, body);
  }

  async post(url, body) {
    return this.fetch("POST", url, body);
  }
  // ответ от сервера везде разный где-то json, где-то текст
  async postResponseText(url, body) {
    return this.fetch2("POST", url, body);
  }

  async put(url, body) {
    return this.fetch("PUT", url, body);
  }

  async delete(url) {
    return this.fetch2("DELETE", url);
  }
}
