export default [
    {
        value: null,
        name: '-',
    },
    {
        value: 'write',
        name: 'Write',
    }, {
        value: 'read',
        name: 'Read',
    },  {
        value: 'denied',
        name: 'Access denied',
    },
]