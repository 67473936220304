<template>
  <div>
  <div @click="confirmDeleteHandler" class="delete-icon" :style="{'opacity': opacity}">
    <img src="../../assets/delete.svg" alt="">
  </div>
    <TheModal
        v-show="confirmDelete"
        title="Do you want to delete this?"
        @close="closeModalHandler">
    <div class="delete">
      <div class="button-container">
        <button v-if="!folderFullPath" @click="deleteFile" class="button">Yes</button>
        <button v-if="folderFullPath" @click="deleteFolder" class="button">Yes</button>
        <button @click="confirmDelete = false" class="button">No</button>
      </div>
    </div>
    </TheModal>
  </div>
</template>

<script>
import {api} from "@/api";
import {mapState} from "vuex";
import {Library} from "@/mixins/mixins";
import TheModal from '@/components/modal/TheModal';

export default {
  name: 'Delete',
  components: {TheModal},

  props: {
    folderFullPath: {
      type: String,
      default: ''
    },
    opacity: {
      type: Number,
      required: false,
    },
    closeModal: {
      type: Function
    },
  },
  data() {
    return {
      confirmDelete: false,
    }
  },
  computed: {
    ...mapState(['files', 'folders', 'library']),
  },
  mixins: [Library],
  methods: {
    closeModalHandler() {
      // console.log('closeModalHandler');
      this.closeModal();
      this.confirmDelete = false;
    },
    confirmDeleteHandler() {
      // console.log('confirmDeleteHandler');
      // console.log('folderFullPath', this.folderFullPath);
      // console.log('files.path', this.files.path);

      if (this.files.path || this.folderFullPath) {
          this.confirmDelete = true;
      }
    },
    deleteFolder() {
      console.log('deleteFolder');
      console.log('folderFullPath', this.folderFullPath);
      console.log('files.path', this.files.path);
      this.$store.commit('library/loading', true);

      api
          .delete(`/api/s3/file?key=${this.folderFullPath}`)
          .then(() => {
            if (this.folders && this.folders.folderIndex) {
              this.$store.dispatch('folders/removedItem', this.folders.folderIndex);
            }
            if (this.files && this.files.fileIndex) {
              this.$store.dispatch('files/removedItem', this.files.fileIndex);
            }

            this.resetData();
            this.fetchAPIData();
            this.$store.commit('library/loading', false);

            this.confirmDelete = false;
            this.closeModal();
          })
          .catch((err) => {
            console.log('The API is facing issues. Please try again later.n' + err);
            this.$store.commit('library/loading', false);
          });
    },
    deleteFile() {
      console.log('deleteFile', this.files.path);
      console.log('folderFullPath', this.folderFullPath);
      console.log('files.path', this.files.path);
      this.$store.commit('library/loading', true);

      api
          .delete(`/api/s3/file?key=${this.files.path}`)
          .then(() => {
            if (this.folders && this.folders.folderIndex) {
              this.$store.dispatch('folders/removedItem', this.folders.folderIndex);
            }
            if (this.files && this.files.fileIndex) {
              this.$store.dispatch('files/removedItem', this.files.fileIndex);
            }

            this.resetData();
            this.fetchAPIData();
            this.$store.commit('library/loading', false);

            this.confirmDelete = false;
            this.closeModal();
          })
          .catch((err) => {
            console.log('The API is facing issues. Please try again later.n' + err);
            this.$store.commit('library/loading', false);
          });
    },
  },
}
</script>

<style src="./operations.scss" lang="scss">
</style>