import set from 'lodash-es/set';
import cloneDeep from 'lodash-es/cloneDeep';

export default {
    namespaced: true,
    state: {
        prefix: null,
    },
    mutations: {
        setMultipleFolders(state, bool) {
            state.multipleFolders = bool;
        },
        setCurrentProperty(state, { path, value }) {
            set(state, path, value);
            state = cloneDeep(state);
        },
        setAll(state, items) {
            state.items = items;
        },
        setPrefix(state, index) {
            state.prefix = index;
        },
        add(state, data) {
            state.items.push(data);
            // state.version += 1;
        },
        remove(state, i) {
            state.items = state.items.filter((item, index) => {
                if (index === i) {
                    return false;
                }
                return true;
            });
        },
    },
    actions: {
        setCurrentProperty({ commit }, { path, value }) {
            commit('setCurrentProperty', { path, value });
        },
        setItems({ commit }, data) {
            commit('setAll', data);
        },
        setPref({ commit }, data) {
            commit('setPrefix', data);
        },
        addedItem({ commit }, item) {
            commit('add', {
                Prefix: item.Prefix,
                thumb: item.thumb
            });
        },
        removedItem({ commit }, index) {
            commit('remove', index);
        },
    },
}