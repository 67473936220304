<template>
  <div class="overlay">
    <div class="modal">
      <div class="modal__title">
        <h5>
          {{ title }}
        </h5>
        <span @click="closeModal"
              class="closed">
          <img src="../../assets/close.svg" alt="close">
        </span>
      </div>
      <div class="modal__body">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheModal',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang='scss'>
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.modal {
  color: #191919;
  width: max-content;
  margin: 0 auto;
  z-index: 10;
  position: relative;
  top: 5%;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #444444;
    color: #FFFFFF;

    .closed {
      cursor: pointer;
    }
  }

  &__body {
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 499px) {
  .modal {
    width: 100%;
  }
}
</style>