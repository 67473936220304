<template>
  <form class="faq-search">
    <input v-model="search" class="faq-search__input" placeholder="Найти ответы" type="text" />
    <button class="faq-search__btn" type="submit" @click.prevent>Поиск</button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      searchQuery: (state) => state.faq.searchQuery
    }),
    search: {
      get() {
        return this.searchQuery;
      },
      set(newValue) {
        this.setSearchQuery(newValue);
      }
    }
  },
  methods: {
    ...mapActions({
      setSearchQuery: 'faq/setSearchQuery'
    })
  }
};
</script>

<style lang="scss" scoped>
.faq-search {
  display: flex;
  align-items: center;
  height: 40px;
  // .faq-search__input
  &__input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid var(--light-gray);
    padding: 0 24px;
    color: var(--gray);
    &::placeholder {
      color: var(--light-gray);
    }
  }
  // .faq-search__btn
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    color: var(--white);
    background-color: var(--gold);
  }
}
</style>
