<template>
  <div class="custom-checkbox" :class="{ checked: checked }" @click="toggle()">
    <div class="custom-checkbox__icon" />
    <div class="custom-checkbox__label">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: ['title', 'value'],
  computed: {
    checked() {
      return this.value;
    }
  },
  methods: {
    toggle() {
      this.$emit('change', !this.checked);
    }
  }
};
</script>

<style lang="scss">
.custom-checkbox {
  display: flex;
  // justify-content: space-between;
  justify-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 20px;

  &.checked {
    .custom-checkbox__icon {
      background-image: url('../../assets/radio.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.custom-checkbox__label {
  color: white;
  padding-left: 10px;
}

.custom-checkbox__icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
}
</style>
