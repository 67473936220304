import { api } from "@/api";

export default {
  namespaced: true,
  state: {
    id: null,
    role: "manager",
    error: false,
    isLoading: false,
  },
  getters: {
    isAuth: ({ id }) => !!id,
  },
  mutations: {
    login(state, userData) {
      state.id = userData.id;
      state.role = userData.role;
      state.error = false;
    },
    checkInfoUser(state, info) {
      state.role = info.role;
      state.id = info.id;
    },
    logout(state) {
      state.id = null;
      state.role = "";
      state.error = false;
    },
    error(state) {
      state.error = true;
    },
    loading(state, bool) {
      state.isLoading = bool;
    },
  },
  actions: {
    checkInfoUser({ commit }) {
      api.get("/api/auth/token").then((res) => {
        // DG: надо было проверять что нам пришло в ответе
        if (res.id) {
          commit("checkInfoUser", res);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    login({ commit }, authData) {
      commit("loading", true);
      api
        .post(
          "/api/auth/login",
          JSON.stringify({
            username: authData.username,
            password: authData.password,
          })
        )
        .then((res) => {
          if (res.statusCode === 401) {
            commit("error");
          }

          commit("login", {
            id: res.userId,
            role: res.role,
          });
          commit("loading", false);
        })
        .catch(() => {
          commit("error");
          commit("loading", false);
        });
    },
    logout({ commit }) {
      commit("logout");
    },
  },
};
