<template>
  <div class="faq">
    <div class="faq__left left-faq">
      <div class="left-faq__info">
        <p>Everything you need to know about wood&washi</p>
        <p>
          Can't find the information you need?
          <button @click="toggleFeedbackForm">Contact us</button>
        </p>
      </div>

      <div class="faq__category">
        <faq-category-item
          v-for="category in categories"
          :key="category.id"
          :item="category"
        ></faq-category-item>
      </div>
    </div>
    <faq-feedback v-if="feedbackFormOpen" @closeFeedback="toggleFeedbackForm"></faq-feedback>
    <div class="faq__right right-faq">
      <div class="right-faq__container">
        <div class="right-faq__header">
          <h2>Frequently asked questions</h2>
          <faq-search class="right-faq__search"></faq-search>
          <button class="right-faq__close" @click="setFaqOpen(false)">
            <faq-close-icon></faq-close-icon>
          </button>
        </div>
        <!-- TODO сделать нормальный лоадер -->
        <div v-if="faqsLoading">Loading</div>
        <div v-else-if="sortedAndSearchedFaqs.length" class="right-faq__body">
          <faq-item v-for="faq in sortedAndSearchedFaqs" :key="faq.id" :item="faq"></faq-item>
        </div>
        <div v-else>No FAQ in this category</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FaqItem from './FaqItem.vue';
import FaqSearch from './FaqSearch.vue';
import FaqCategoryItem from './FaqCategoryItem.vue';
import FaqCloseIcon from './icon/FaqCloseIcon.vue';
import { api } from '@/api';
import FaqFeedback from '@/components/faq/FaqFeedback.vue';
export default {
  components: { FaqItem, FaqSearch, FaqCategoryItem, FaqCloseIcon, FaqFeedback },
  data() {
    return {
      faqsLoading: false,
      feedbackFormOpen: false
    };
  },
  computed: {
    ...mapState({
      faqs: (state) => state.faq.faqs,
      categories: (state) => state.faq.categories
    }),
    ...mapGetters({
      sortedAndSearchedFaqs: 'faq/sortedAndSearchedFaqs'
    })
  },
  created() {
    this.fetchCategories();
    this.fetchFaqs();
  },
  methods: {
    ...mapActions({
      setFaqOpen: 'faq/setFaqOpen',
      setFaqs: 'faq/setFaqs',
      setCategories: 'faq/setCategories'
    }),
    toggleFeedbackForm() {
      this.feedbackFormOpen = !this.feedbackFormOpen;
    },
    fetchCategories() {
      if (this.categories.length) return;

      api.get('/api/category-faq').then((res) => {
        if (res.result) {
          this.setCategories(res.result);
        } else {
          throw new Error('Fetch failed or empty');
        }
      });
    },
    fetchFaqs() {
      if (this.faqs.length) return;

      api
        .get('/api/faqs')
        .then((res) => {
          this.faqsLoading = true;

          if (res.result) {
            this.setFaqs(res.result);
          } else {
            throw new Error('Fetch failed or empty');
          }
        })
        .finally(() => {
          this.faqsLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.faq {
  position: fixed;
  top: 0;
  // top: 122px;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  // padding: 0 40px 40px;
  padding: 40px;
  z-index: 10;
  background-color: var(--bg-gray);
  column-gap: 24px;
  @media (max-width: 1063px) {
    top: 162px;
  }
  // .faq__left
  &__left {
    flex: 0 0 334px;
  }
  // .faq__category
  &__category {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  // .faq__right
  &__right {
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
  }
}
.left-faq {
  // .left-faq__info
  &__info {
    padding: 33px 24px 31px;
    margin-bottom: 12px;
    background: white;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: var(--dark-gray);
      &:first-child {
        margin-bottom: 32px;
      }
      button {
        border: none;
        color: var(--gold);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.right-faq {
  background-color: var(--white);
  // .right-faq__container
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    padding: 32px;
    // вычитаем ширину скроллбара, чтобы скрыть его
    margin-right: -17px;
  }
  // .right-faq__header
  &__header {
    position: relative;
    h2 {
      margin: 0px 0px 24px 0px;
      font-size: 30px;
      font-weight: 500;
      line-height: 140%;
      color: var(--gray);
      letter-spacing: 1px;
    }
  }
  // .right-faq__search
  &__search {
    margin-bottom: 32px;
  }
  // .right-faq__body
  &__body {
    .faq-item {
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }
  // .right-faq__close
  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    top: -16px;
    right: -16px;
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--gray);

    &:hover {
      color: var(--gold);
    }
  }
}
</style>
