
export default {
  namespaced: true,
  state: {
    showLibrary: false,
    showCerificate: false,
    app: "library"
  },
  mutations: {
    showCerificate(state) {
      state.showCerificate = !state.showCerificate;
    },
    openLibrary(state) {
      state.app = "library";
    },
    openConstructor(state) {
      state.app = "constructor";
    },
    openMain(state) {
      state.app = "main";
    },
    openCertificates(state) {
      state.app = "cert";
    },
    openNavigation(state) {
      state.app = "navigate";
    },
  },
  actions: {
    showCerificate({ commit }) {
      commit('showCerificate');
    },
    openNavigation({ commit }) {
      console.log('openNavigation');
      commit('openNavigation');
    },
    openLibrary({ commit }) {
      console.log('openLibrary');
      commit('openLibrary');
    },
    openConstructor({ commit }) {
      commit('openConstructor');
    },
    openMain({ commit }) {
      commit('openMain');
    },
    openCertificates({ commit }) {
      commit('openCertificates');
    },
  },
  modules: {
  }
}
