<template>
  <div></div>
</template>

<script>
export default {
  name: 'Certificate',
  mounted() {
    this.$store.dispatch('appCtx/openCertificates', {});
  }
}
</script>

<style scoped>

</style>